import React, { useContext, useEffect } from 'react';
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../Auth";
import * as FirebaseServices from "../../Services/firebase";
import ProfileForm from './ProfileForm';


const AddProfile = () => {
  const history = useHistory();
  const usersContext = useContext(AuthContext);
  const {currentUser } = usersContext;
  const [userDetails, setUserDetails] = React.useState(null);

  // Prefilling the user details form with first/last name and email.
  useEffect(() => {
    const fetchData = async () => {
      //  setIsLoading(true);
      try {
        const user = await FirebaseServices.getUserDetails(currentUser.uid);
        console.log(user);
        setUserDetails({
          firstName: user.firstName,
          lastName: user.lastName,
          email: currentUser.email,
        });
      } catch (error) {
        alert(error);
      }
      ///  setIsLoading(false);
    };

    fetchData();
  }, [currentUser]);



  const onSubmit = async (values, imageFilePath, setIsOpen, skills, industries) => {

    console.log(values);
    console.log(values.linkedin);
    setIsOpen(true);
    try {

      //const originalimage = values.image[0];
      //const imageAsFile = await Utils.getCompressedImage(originalimage);
      //const imgDownloadURL = await FirebaseServices.uploadFile(currentUser.uid, imageAsFile);
      const imgDownloadURL = imageFilePath
      await FirebaseServices.addProfile(currentUser.uid, values.firstName, values.lastName, values.email, values.linkedin,
        values.city, values.country, skills, industries, values.lookingfor, values.externalLink, values.publishReason, imgDownloadURL, values.type);
        setIsOpen(false);
      history.push("/project-submit-success");
      
    } catch (error) {
      setIsOpen(false);
      console.log(error);
    }
    
  }


  return (
    userDetails && (<ProfileForm isEditMode={false} userDetails={userDetails} submitForm={onSubmit}></ProfileForm>)
  );

}

export default withRouter(AddProfile);