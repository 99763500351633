import { Avatar } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import MuiListItem from "@material-ui/core/ListItem";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import GroupIcon from '@material-ui/icons/Group';
import HomeIcon from '@material-ui/icons/Home';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsIcon from '@material-ui/icons/Settings';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import React from 'react';
import { Link, Route, Switch } from "react-router-dom";
import Home from './Home';
import AddProfile from './Profiles/CreateProfile/AddProfile';
import EditProfile from './Profiles/CreateProfile/EditProfile';
import ProfileDetail from './Profiles/ProfileDetails/ProfileDetail';
import ProfilesList from './Profiles/ProfilesList/ProfilesList';
import AddProject from './Projects/CreateProject/AddProject';
import EditProject from './Projects/CreateProject/EditProject';
import ProjectSubmitSuccess from './Projects/CreateProject/ProjectSubmitSuccess';
import ProjectDetail from './Projects/ProjectDetails/ProjectDetail';
import ProjectsList from './Projects/ProjectsList/ProjectsList';
import Faq from './Screens/Faq';
import Terms from './Screens/Terms';
import MyAccount from './Screens/MyAccount';
import * as FirebaseServices from "./Services/firebase";
import VisibilityIcon from '@material-ui/icons/Visibility';

/*
import BorderColorIcon from '@material-ui/icons/BorderColor';
*/

const drawerWidth = 240;


const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#2C3C4F",
      color: "white",
      fontWeight: "light"
    },
    "&$selected:hover": {
      backgroundColor: "2C3C4F",
      color: "white"
    },
    "&:hover": {
      backgroundColor: "#2C3C4F",
      color: "white"
    }
  },
  selected: {}
})(MuiListItem);


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },


  appBar: {

    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: "white",
    minHeight: "128px",
  },

  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  toolBar: {
    minHeight: "128px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    color: theme.palette.primary.main,
  },
  logoHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  drawer: {

    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    background: theme.palette.primary.main,
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  boldy: {
    fontWeight: 'lighter',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  linkContainer: {
    textDecoration: 'none',
  },
  nested: {
    paddingLeft: theme.spacing(9),
  },

  dividerColor: {
    // backgroundColor: "#4bb170"
  },

}));

const handleLogout = () => {
  FirebaseServices.doSignOut();
}



export default function ClippedDrawer() {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {

    if (matches) {
      setMobileOpen(!mobileOpen);
    }

  };


  const handleClick = () => {
    setOpen(!open);
  };

  const isMenuItemSelected = (menuItem) => {
    if (window.location.pathname.includes(menuItem)) {
      return true;
    }
    else {
      return false;
    }
  };



  const isNoWrap = (button) =>{
      if (!button){
        return false;
      }
      else{
        return true;
      }
    
    }

  const getAppbarTitle = () => {

    var title = "";
    var subtitle = "";
    var button = undefined;

    if (window.location.pathname.includes('projects')) {
      title = "Projects";
      subtitle = "";
      if (!matches) {
        button = <Link className={classes.linkContainer} to="/addproject"><Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<AddCircleOutlineIcon />}
        >
          Submit
        </Button>
        </Link>
      }
    }
    else if (window.location.pathname.includes('profiles')) {
      title = "Profiles";
      subtitle = "";
      if (!matches) {
        button = <Link className={classes.linkContainer} to="/addprofile"><Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<AddCircleOutlineIcon />}
        >
          Submit
        </Button>
        </Link>
      }
    }
    else {
      switch (window.location.pathname) {
        case '/home':
          title = "Inside";
          subtitle = "Use Cactus Inside to discover projects & people.";
          break;
        case '/addprofile':
          title = "Profile Submission";
          subtitle = "";
          break;
        case '/addproject':
          title = "Project Submission";
          subtitle = "";
          break;
        case '/project-submit-success':
          title = "Project Submission";
          subtitle = "";
          break;
        case '/faq':
          title = "FAQ";
          subtitle = "";
          break;
          case '/terms':
            title = "Privacy & Terms";
            subtitle = "";
            break;
        case '/myaccount':
          title = "My Account";
          subtitle = "";
          break;
        default:
          title = "Hello";
          subtitle = "";
          break;
      }
    }
    return (<div className={classes.titleContainer}>
      <Container>
        <Typography variant="h4" >
          {title}
        </Typography>
        <Typography variant="overline" noWrap={isNoWrap(button)}>
          {subtitle}
        </Typography>
      </Container>
      {button &&
        <Container>
          {button}
        </Container>
      }


    </div>
    )
  }


  
  const drawer = (
    <div className={classes.drawerContainer}>
      <List style={{ color: '#d6e2ee' }}>

        <ListItem button key="logo">
          <Container className={classes.logoHeader}>
            <Avatar src="https://firebasestorage.googleapis.com/v0/b/cactusinside-d4aa9.appspot.com/o/assets%2FnewLogoGreen.png?alt=media&token=fb5e35ef-0376-42f1-8676-8fb635f2a76f" alt="Girl in a jacket" style={{ width: "50", height: "50" }}></Avatar>
            <Typography variant="h6" noWrap style={{ color: "white" }} className={classes.boldy}>
              Cactus Inside
  </Typography>
          </Container>
        </ListItem>

        <Divider style={{ backgroundColor: "#4bb170" }} variant="middle" />
        <ListItem onClick={handleDrawerToggle} selected={isMenuItemSelected("home")} button key="home" component={Link} to="/home" >
          <ListItemIcon><HomeIcon style={{ color: '#d6e2ee' }} /></ListItemIcon>
          <ListItemText disableTypography
            primary={<Typography variant="overline" >Home</Typography>} />
        </ListItem>


        <ListItem onClick={handleDrawerToggle} selected={isMenuItemSelected("projects")} button key="projects" component={Link} to="/projects" >
          <ListItemIcon><WhatshotIcon style={{ color: '#d6e2ee' }} /></ListItemIcon>
          <ListItemText disableTypography
            primary={<Typography variant="overline" >Projects</Typography>} />
        </ListItem>

        <ListItem onClick={handleDrawerToggle} selected={isMenuItemSelected("profiles")} button key="profiles" component={Link} to="/profiles" >
          <ListItemIcon><GroupIcon style={{ color: '#d6e2ee' }} /></ListItemIcon>
          <ListItemText disableTypography
            primary={<Typography variant="overline" >Profiles</Typography>} />
        </ListItem>

        <ListItem button onClick={handleClick}>
          <ListItemIcon><ControlPointIcon style={{ color: '#d6e2ee' }} /></ListItemIcon>
          <ListItemText disableTypography primary={<Typography variant="overline" >Submit</Typography>} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem className={classes.nested} selected={isMenuItemSelected("addproject")} button key="addproject" onClick={handleDrawerToggle} component={Link} to="/addproject" >

              <ListItemText disableTypography
                primary={<Typography variant="overline">New Project</Typography>} />
            </ListItem>

            <ListItem className={classes.nested} selected={isMenuItemSelected("addprofile")} button key="addprofile" onClick={handleDrawerToggle} component={Link} to="/addprofile" >

              <ListItemText disableTypography
                primary={<Typography variant="overline">New Profile</Typography>} />
            </ListItem>
          </List>
        </Collapse>


        <Divider variant="middle" classes={{ root: classes.dividerColor }} />
        <ListItem onClick={handleDrawerToggle} selected={isMenuItemSelected("myaccount")} button key="myaccount" component={Link} to="/myaccount" >
          <ListItemIcon><SettingsIcon style={{ color: '#d6e2ee' }} /></ListItemIcon>
          <ListItemText disableTypography
            primary={<Typography variant="overline" >My Account</Typography>} />
        </ListItem>

        <ListItem onClick={handleDrawerToggle} selected={isMenuItemSelected("faq")} button key="faq" component={Link} to="/faq" >
          <ListItemIcon><LiveHelpOutlinedIcon style={{ color: '#d6e2ee' }} /></ListItemIcon>
          <ListItemText disableTypography
            primary={<Typography variant="overline" >FAQ</Typography>} />
        </ListItem>

        <ListItem onClick={handleDrawerToggle} selected={isMenuItemSelected("terms")} button key="terms" component={Link} to="/terms" >
          <ListItemIcon><VisibilityIcon style={{ color: '#d6e2ee' }} /></ListItemIcon>
          <ListItemText disableTypography
            primary={<Typography variant="overline" >Privacy and Terms</Typography>} />
        </ListItem>

      </List>




      <Divider variant="middle" classes={{ root: classes.dividerColor }} />

      {['Logout'].map((text, index) => (
        <ListItem button key={text} onClick={handleLogout}  >
          <ListItemIcon><ExitToAppIcon style={{ color: '#d6e2ee' }} /></ListItemIcon>
          <ListItemText disableTypography
            primary={<Typography variant="overline" style={{ color: '#d6e2ee' }}>{text}</Typography>} />
        </ListItem>
      ))}

    </div>
  );




  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>

        <Toolbar className={classes.toolBar}>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          {getAppbarTitle()}
        </Toolbar>

      </AppBar>


      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>

        <Switch>
          <Route path="/home" exact component={Home} />
          <Route path="/projects" exact component={ProjectsList} />
          <Route path="/profiles" exact component={ProfilesList} />
          <Route path="/addproject" component={AddProject} />
          <Route path="/editproject/:id" component={EditProject} />
          <Route path="/editprofile/:id" component={EditProfile} />
          <Route path="/addprofile" component={AddProfile} />
          <Route path="/projects/:id" component={ProjectDetail} />
          <Route path="/profiles/:id" component={ProfileDetail} />
          <Route path="/myaccount" component={MyAccount} />
          <Route path="/faq" component={Faq} />
          <Route path="/terms" component={Terms} />
          <Route path="/project-submit-success" component={ProjectSubmitSuccess} />
        </Switch>



      </main>

    </div>
  );
}

