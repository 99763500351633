import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';

const ProgressDialog = ({isOpen}) => {

  return (
  
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom:"20px", marginTop: "20px" ,marginRight: "20px", marginLeft: "20px"  }}>
        <DialogTitle id="alert-dialog-title">{"Submitting...."}</DialogTitle>
        <DialogContent>
        <CircularProgress />
        </DialogContent>

        </div>
      </Dialog>

  );
}

export default ProgressDialog;