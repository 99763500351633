import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { useForm } from "react-hook-form";
import { withRouter } from "react-router";
import * as Yup from 'yup';
import * as FirebaseServices from "../Services/firebase";

const schema = Yup.object({
  email: Yup.string()
    .email('Invalid email address')
    .required('Required'),
});

const ResetPassword = ({ history }) => {
  const classes = useStyles();
  const [errorMsg, setErrorMsg] = React.useState();
  const [isSuccess, setIsSuccess] = React.useState(false);

  const { register, handleSubmit, errors } = useForm({
    validationSchema: schema
  });


  const onSubmit = async (values) => {

    try {
      await
        FirebaseServices.resetUserPassword(values.email);
      setIsSuccess(true)
    } catch (error) {
      setErrorMsg(error.message);
    }

  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>

        {isSuccess ?
          <Container style={{marginTop: "10px"}}>
            <Typography variant="body2">
              An email has been sent with instructions to reset your password.
            </Typography>
            <Link  href="./login" variant="body2">
            <Typography style={{marginTop: "30px"}} variant="body2">
              Back to Login Page
            </Typography>
              </Link>
          </Container>
       :
          <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  inputRef={register}
                  error={errors.email ? true : false}
                  helperText={errors.email ? (
                    errors.email.message
                  ) : null}

                />
              </Grid>

              {errorMsg && <Grid item xs={12}>
                <Alert severity="error">{errorMsg}</Alert>
              </Grid>}

            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Reset Password
          </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Link href="./login" variant="body2">
                  Already have an account? Sign in
              </Link>
              </Grid>
            </Grid>
          </form>

        }
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Cactus Inside
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


export default withRouter(ResetPassword);