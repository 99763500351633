import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from "@material-ui/core/colors";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import clsx from "clsx";
import React, { useContext, useState } from 'react';
import { AuthContext } from "../Auth";
import * as FirebaseServices from "../Services/firebase";


const ContactDialog = ({ isOpen, handleClose, entityId, entityType, children }) => {

  const classes = useStyles();

  const usersContext = useContext(AuthContext);
  const {currentUser } = usersContext;

  
  const [message, setMessage] = React.useState();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);


  const buttonClassname = clsx({
    [classes.buttonIdle]: !isSuccess,
    [classes.buttonSuccess]: isSuccess
  });


  const onSubmit = async (values) => {


    if (!isSubmitting) {
      try {
        setIsSubmitting(true);
        setIsSuccess(false);
        console.log(currentUser.uid + " " + entityId + " " + message+ " Entity type:" + entityType);
        await FirebaseServices.sendMessageToProjectOwner(currentUser.uid, entityId, entityType,  message);
        setIsSuccess(true);
      } catch (error) {
        console.log(error);
      }
      setIsSubmitting(false);
    }
  }

  const handleChange = event => {
    setMessage(event.target.value);
  }

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">

        <DialogContent>
          <DialogContentText>
            Message to the project owner
          </DialogContentText>
          <TextField
            name="message"
            multiline
            onChange={handleChange}
            rows={5}
            rowsMax={Infinity}
            variant="outlined"
            autoFocus
            margin="dense"
            id="name"
            type="text"
            fullWidth
          />

          <DialogContentText>
            The person will receive the message and your contact details via an email from Cactus
          </DialogContentText>

        </DialogContent>

        <DialogActions>

          <Button className={classes.buttonIdle} variant="contained" onClick={handleClose}>
            Close
                </Button>

          <div className={classes.wrapper}>
            <Button variant="contained" disabled={isSuccess || isSubmitting} onClick={onSubmit} className={buttonClassname}>
              {isSuccess ? <div> Sent! </div> : <div>Send </div>}
            </Button>
            {isSubmitting && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>


        </DialogActions>


      </Dialog>
    </div>
  );
}

export default ContactDialog;

const useStyles = makeStyles((theme) => ({

  disabled: {},
  buttonSuccess: {
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
    "&:disabled": {
      color: theme.palette.secondary.main,
    }
  },
  buttonIdle: {
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#2C3C4F",
      color: "white"
    }
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative"
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }

}));