import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import uuid from 'react-uuid'

const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID
});

const db = firebase.firestore();

// References 
const projectsRef = db.collection('projects');
const usersRef = db.collection('users');
const messagesRef = db.collection('messages');
const profilesRef = db.collection('profiles');

// AUTH APIs
export const doCreateUserWithEmailAndPassword = async (pEmail, pPassword, pFirstName, pLastName, pIsPromotion) => {
    console.log("Creating user");
    const authResult = await app.auth().createUserWithEmailAndPassword(pEmail, pPassword);
    console.log(authResult.user.uid);
    usersRef.doc(authResult.user.uid)
        .set({
            created: firebase.firestore.FieldValue.serverTimestamp(),
            firstName: pFirstName,
            lastName: pLastName,
            isPromotion: pIsPromotion,
        });
}

export const doSignInWithEmailAndPassword = (email, password) =>
    app.auth().signInWithEmailAndPassword(email, password);

export const doSignOut = () =>
    app.auth().signOut();


export const resetUserPassword = async (emailAddress) => {
    var auth = firebase.auth();
    await auth.sendPasswordResetEmail(emailAddress);
};

export const getProjects = async (type) => {
    try {
        console.log("Retrieving projects");

        const snapshot = await projectsRef.where("type", "==", type).where("isReviewed","==", true).get();

        return snapshot.docs.map((doc) => (
            { id: doc.id, ...doc.data() }
        ));

    }
    catch{
        console.log("failed to retrieve projects");
    }
};


export const getProfiles = async (type) => {
    try {
        console.log("Retrieving profiles");
        const snapshot = await profilesRef.where("type", "==", type).where("isReviewed","==", true).get();
        return snapshot.docs.map((doc) => (
            { id: doc.id, ...doc.data() }
        ));

    }
    catch{
        console.log("failed to retrieve profiles");
    }
};


export const updateUserPassword = async (currentPassword, newPassword) => {
    var user = firebase.auth().currentUser;
    var cred = firebase.auth.EmailAuthProvider.credential(
        user.email, currentPassword);
    await user.reauthenticateWithCredential(cred);
    await user.updatePassword(newPassword);

}

export const updateUserDetails = async (firstName, lastName, email) => {
    try {
        var user = firebase.auth().currentUser;
        user.updateEmail(email);

        usersRef.doc(user.uid)
            .set({
                updated: firebase.firestore.FieldValue.serverTimestamp(),
                firstName: firstName,
                lastName: lastName,
            });
    }
    catch{
        console.log("Failed to update user details");
    }
}


export const getUserProjects = async () => {
    var user = firebase.auth().currentUser;
    try {
        var projects = [];
        console.log("Getting projects for user id: " + user.uid);
        const snapshot = await projectsRef.where("createdBy", "==", user.uid).get();

        snapshot.docs.map((doc) => (
            projects.push({ id: doc.id, ...doc.data() })
        ));
        console.log(projects);
        return projects;
    }
    catch{
        console.log("filade to get user with id");
    }
}

export const getUserProfiles = async () => {

    var user = firebase.auth().currentUser;
    try {
        var profiles = [];
        console.log("Getting profiles for user id: " + user.uid);
        const snapshot = await profilesRef.where("createdBy", "==", user.uid).get();

        snapshot.docs.map((doc) => (
            profiles.push({ id: doc.id, ...doc.data() })
        ));
        console.log(profiles);
        return profiles;
    }
    catch{
        console.log("filade to get user with id");
    }
}


export const getUserDetails = async (userId) => {

    try {
        console.log("Getting user details with id: " + userId);
        const doc = await usersRef.doc(userId).get();
        const user = doc.data();
        return user;
    }
    catch{
        console.log("filade to get user with id");
    }
}

export const getFullProjectDetails = async (projectId) => {

    try {
        const doc = await projectsRef.doc(projectId).get();
        console.log("Getting project id: " + projectId);
        const contact = await projectsRef.doc(projectId).collection("contacts").doc("contact").get();
        const project = doc.data();
        project.email = contact.data().email;
        project.id = doc.id;
        return project;
    }
    catch{
        console.log("failed to get project details");
    }
}

export const getProjectDetails = async (projectId) => {

    try {
        const doc = await projectsRef.doc(projectId).get();
        console.log("Getting project id: " + projectId);
        const project = doc.data();
        project.id = doc.id;
        return project;
    }
    catch{
        console.log("failed to get project details");
    }
}

export const deleteProjectWithId = async (projectId) => {

    try {
        await projectsRef.doc(projectId).delete();
        console.log("Deleted project id: " + projectId);
    }
    catch{
        console.log("failed to delete project with id: " + projectId);
    }
}

export const deleteProfileWithId = async (profileId) => {

    try {
        await profilesRef.doc(profileId).delete();
        console.log("Deleted profile with  id: " + profileId);
    }
    catch{
        console.log("failed to delete profile with id: " + profileId);
    }
}



export const getFullProfileDetails = async (profileId) => {

    try {
        console.log("Getting profile id: " + profileId);
        const doc = await profilesRef.doc(profileId).get();
        const contact = await profilesRef.doc(profileId).collection("contacts").doc("contact").get();
        const profile = doc.data();
        profile.email = contact.data().email;
        profile.id = doc.id;
        return profile;
    }
    catch{
        console.log("failed to get profile details");
    }
}
export const getProfileDetails = async (profileId) => {

    try {
        console.log("Getting profile id: " + profileId);
        const doc = await profilesRef.doc(profileId).get();
        const profile = doc.data();
        profile.id = doc.id;
        console.log(profile);
        return profile;
    }
    catch{
        console.log("failed to get profile details");
    }
}


export const addProject = async (userId, title, description, lookingFor, externalLink, industry, stage, imageURL, city, country
    , firstName, lastName, email, linkedin, type) => {

    const isReviewed = false;

    const newProjectRef = await projectsRef
        .add({
            title,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            createdBy: userId,
            description,
            lookingFor,
            externalLink,
            industry,
            stage,
            city,
            country,
            firstName,
            lastName,
            linkedin,
            type,
            isReviewed,
        });
    if (imageURL) {
        await newProjectRef
            .set({
                imageURL,
            }, { merge: true });
    }

    // Meant to 'hide' an email from a project metadata retrieved. (Firestore is a shallow get)
    newProjectRef.collection("contacts").doc("contact").set({
        email,
    })

};

export const updateProfileWithId = async (userId, profileId, firstName, lastName, email, linkedin, city, country, skills, industries, lookingFor, externalLink,
    publishReason, imageURL,type) => {

    console.log("Updating profile with id: " + profileId);

    await profilesRef.doc(profileId)
        .update({
            updated: firebase.firestore.FieldValue.serverTimestamp(),
            createdBy: userId,
            firstName,
            lastName,
            linkedin,
            city,
            country,
            skills,
            industries,
            lookingFor,
            externalLink,
            publishReason,
            type,
        });

    if (imageURL) {
        await profilesRef.doc(profileId)
            .set({
                imageURL,
            }, { merge: true });
    }

    console.log("new email: " + email + " for id: " + profileId);
    profilesRef.doc(profileId).collection("contacts").doc("contact").set({
        email,
    })

};

export const updateProjectWithId = async (projectId, userId, title, description, lookingFor, externalLink, industry, stage, imageURL, city, country
    , firstName, lastName, email, linkedin, type) => {

    console.log("Updating project with id: " + projectId);
    console.log("industry is" + industry);
    await projectsRef.doc(projectId)
        .update({
            title,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            createdBy: userId,
            description,
            lookingFor,
            externalLink,
            industry,
            stage,
            city,
            country,
            firstName,
            lastName,
            linkedin,
            type,
        });

    if (imageURL) {
        await projectsRef.doc(projectId)
            .set({
                imageURL,
            }, { merge: true });
    }
    console.log("updated email: " + email + "for project: " + projectId);
    projectsRef.doc(projectId).collection("contacts").doc("contact").set({
        email,
    })

};


export const addProfile = async (userId, firstName, lastName, email, linkedin, city, country, skills, industries, lookingFor, externalLink,
    publishReason, imageURL, type) => {

    const isReviewed = false;

    const newProjectRef = await profilesRef
        .add({
            created: firebase.firestore.FieldValue.serverTimestamp(),
            createdBy: userId,
            firstName,
            lastName,
            linkedin,
            city,
            country,
            skills,
            industries,
            lookingFor,
            externalLink,
            publishReason,
            type,
            isReviewed,
        });

        if (imageURL) {
            await newProjectRef
                .set({
                    imageURL,
                }, { merge: true });
        }
    

    // Meant to 'hide' an email from a project metadata retrieved. (Firestore is a shallow get)
    newProjectRef.collection("contacts").doc("contact").set({
        email,
    })

};

export const sendMessageToProjectOwner = async (senderId, entityId, entityType, message) => {

    await messagesRef
        .add({
            senderId,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            entityId,
            entityType,
            message,
        });

};


export const uploadFile = async (userId, file) => {

    if (!file) {
        return undefined;
    }
    console.log("uploading in uploadFile");
    uuid(); 
    const storageRef = firebase.storage().ref("projects/" + userId + "/" + uuid());
    try {
        const snapshot = await storageRef.put(file);
        console.log('Uploaded a blob or file!');
        const downloadURL = await snapshot.ref.getDownloadURL();
        console.log("file available at:" + downloadURL);
        return downloadURL;
    }
    catch{
        console.log("failed to upload file and get link");
    }

}
export default app;
