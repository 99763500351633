
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import SearchIcon from '@material-ui/icons/Search';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import React from 'react';
import { withRouter } from "react-router";


const Instructions = () => {

    const classes = useStyles();

    return (
        <Container className={classes.mainContainer} maxWidth="lg">
            <Paper>
                <Container className={classes.instructions}>
                    <List>
                        <ListItem className={classes.listItemFirst}>
                            <Avatar>
                                <CreateIcon style={{fill: "#32465e"}}></CreateIcon>
                            </Avatar>
                            <ListItemText className={classes.listItemText} primary="1: Submitting your project" secondary="Fill each field with correct information about your project. You can find the criteria for the projects in our FAQ." />

                        </ListItem>
                        <div className={classes.vertical}></div>
                        <ListItem className={classes.listItem}>
                            <Avatar>
                                <SearchIcon style={{fill: "#32465e"}}></SearchIcon>
                            </Avatar>
                            <ListItemText className={classes.listItemText} primary="2. Evaluation" secondary="We will review your submission within a couple of days and either publish your profile on the platform or ask for clarification.
" />
                        </ListItem>
                        <div className={classes.vertical}></div>
                        <ListItem className={classes.listItem}>
                            <Avatar>
                                <TrendingUpIcon style={{fill: "#32465e"}}></TrendingUpIcon>
                            </Avatar>
                            <ListItemText className={classes.listItemText} primary="3. Updating your project" secondary="You can edit or delete your project on the platform at anytime." />
                        </ListItem>
                    </List>
                </Container>
            </Paper>

        </Container>
    );
}

export default withRouter(Instructions);

const useStyles = makeStyles((theme) => ({

    mainContainer: {
        marginTop: theme.spacing(20),
    },
    instructions: {
        width: '100%',
        maxWidth: 1060,
        backgroundColor: theme.palette.background.paper,
    },
    vertical: {
        borderLeft: '1px solid lightgrey',
        height: '100px',
        marginLeft: '2.5em',
        marginBottom: -30,
        marginTop: -30,
    },
    listItem: {
        marginTop: 0,
        marginBottom: 0,
    },
    listItemFirst: {
        marginBottom: 0,
    },
    listItemText: {
        marginLeft: 20,

    }
}));

