import { CardActions, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import React, { useEffect } from 'react';
import ContactDialog from '../../Components/ContactDialog';
import * as FirebaseServices from "../../Services/firebase";
import ExtraDetailsPaper from './ExtraDetailsPaper';

export default function ProjectDetail({ match }) {
  const classes = useStyles();
  const [project, setProject] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleDialogOpen = () => {
    setIsOpen(true);
  };

  const handleDialogClose = () => {
    setIsOpen(false);
  };


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        setProject(await FirebaseServices.getProjectDetails(match.params.id));
      } catch (error) {
        alert(error);
      }
      setIsLoading(false);

    };

    fetchData();
  }, [match.params.id]);


  return (
    <Container className={classes.mainContainer} maxWidth="lg">


      {

        isLoading ? (
          <div
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          >
            <CircularProgress />
          </div>
        )
          :
          (

            <Grid container
              spacing={7}
              direction="row"
              justify="flex-start"
              alignItems="center">

              <Grid key="primarycard" item xs={12} sm={12} md={7} lg={7} xl={7}>

                <Paper className={classes.primaryCard} >



                  {project.imageURL ?
                    <div style={{ height: "80px", position: "relative", backgroundImage: `url("${project.imageURL}")`, backgroundSize: "cover", backgroundPosition: "center" }}>
                      <div className={classes.backgroundFilterAfter} />
                    </div>
                    :
                    <div className={classes.altMedia}></div>
                  }


                  <Container className={classes.overlays}>
                    <Card >
                      {project.imageURL ?
                        <CardMedia
                          className={classes.overlayLogo}
                          image={project.imageURL}
                          title="Contemplative Reptile"
                        />
                        :
                        <div className={classes.overlayLogo}><WhatshotIcon className={classes.overlayLogoIcon} /> </div>}
                    </Card>
                  </Container>


                  <Container className={classes.mid} >

                    <div style={{ flex: '2' }}>
                      <Typography variant="h5" component="p">
                        {project.title}
                      </Typography>

                      <div style={{ alignItems: "center", display: 'flex', flexDirection: "row", marginTop: '6px' }} >

                        <Typography variant="body2" >

                          {`${project.firstName} ${project.lastName}`}

                        </Typography>
                        <Typography variant="body2" >

                          {project.linkedin ?

                            <Link href={"//" + project.linkedin}>
                              <img alt="linkedin" style={{ opacity: "0.5", height: '32px', width: '32px' }} src="https://firebasestorage.googleapis.com/v0/b/cactusinside-211a8.appspot.com/o/assets%2Flinkedin.png?alt=media&token=6e380070-f4a0-490a-a146-eed4691fda44"></img>
                            </Link>
                            : ""}

                        </Typography>

                      </div>

                    </div>

                    {
                      <ExtraDetailsPaper style={{ flex: '1' }}
                        industry={project.industry}
                        stage={project.stage}
                        location={`${project.city}, ${project.country}`}
                        createdDate={new Date(project.created.seconds * 1000).toLocaleDateString("en-US")}
                      >
                      </ExtraDetailsPaper>}
                  </Container>




                  <Container className={classes.desc}>
                    <Typography variant="body2" component="p">{project.description}</Typography>
                  </Container>

                  <Container className={classes.bottom}>
                    <Typography variant="body2">
                      <Link underline="always" href={"//" + project.externalLink} color="inherit">
                        {project.externalLink}
                      </Link></Typography>
                  </Container>

                </Paper>

              </Grid>


              <Grid key="secondarycard" item xs={12} sm={12} md={5} lg={5} xl={5}>

                <Card className={classes.secondaryCard} >

                  <CardContent>
                    <Typography variant="overline" component="p">
                      Looking for
                    </Typography>
                    <br></br>

                    <Typography variant="body2" component="p">{project.lookingFor}</Typography>

                  </CardContent>

                  <CardActions style={{ justifyContent: 'center' }}>
                    <Button variant="contained" color="primary" onClick={handleDialogOpen}>
                      Contact Project
                  </Button>
                  </CardActions>
                </Card>

              </Grid>


            </Grid>
          )}

      <ContactDialog isOpen={isOpen} handleClose={handleDialogClose} entityId={project.id} entityType="project" >
      </ContactDialog>

    </Container>
  );
}


const useStyles = makeStyles((theme) => ({
  primaryCard: {
    minHeight: 500,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "stretch",

  },
  secondaryCard: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "stretch",
  },

  top: {
    flex: 3,
    display: "flex",
    flexDirection: "column",
  },

  mid: {
    flexWrap: "wrap",
    marginTop: "12%",
    flex: "2",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  desc: {
    marginTop: "5%",
    flex: "2",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  bottom: {
    flex: "1"
  },
  overlays: {
    display: 'flex',
    position: 'absolute',
    justifyContent: 'flex-start',
    alignItems: 'center',
    left: "5%",
    top: "80px",
    transform: "translate(0%, -50%)",
  },

  altMedia: {
    background: "linear-gradient(90deg, rgba(50,70,94,1) 18%, rgba(64,95,119,1) 50%, rgba(73,102,135,1) 100%)",
    height: "80px",
  },

  overlayLogoIcon: {
    height: theme.spacing(10) / 1.7,
    width: theme.spacing(10) / 1.7,
  },

  overlayLogo: {
    height: theme.spacing(10),
    width: theme.spacing(10),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mainContainer: {
    marginTop: theme.spacing(20),
  },


  backgroundFilterAfter: {
    WebkitBackdropFilter: "blur(5px)", /* Use for Safari 9+, Edge 17+ (not a mistake) and iOS Safari 9.2+ */
    backdropFilter: "blur(5px)", /* Supported in Chrome 76 */
    display: "block",
    position: "absolute",
    width: "100%",
    height: "100%",
  },
}));
