import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from "@material-ui/core/colors";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import React, { useEffect, useContext, useState } from 'react';
import { AuthContext } from "../Auth";
import * as FirebaseServices from "../Services/firebase";


const DeleteDialog = ({ isOpen, handleClose, entityId, entityType, children }) => {

    const classes = useStyles();
    const usersContext = useContext(AuthContext);
    const {currentUser } = usersContext;

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);


    const buttonClassname = clsx({
        [classes.buttonIdle]: !isSuccess,
        [classes.buttonSuccess]: isSuccess
    });

    
  useEffect(() => {
      if (isOpen)  {
        setIsSubmitting(false);
        setIsSuccess(false);
      }
  

  }, [isOpen]);


    const onSubmit = async (values) => {


        if (!isSubmitting) {
            try {
                setIsSubmitting(true);
                setIsSuccess(false);
                console.log(currentUser.uid + " " + entityId + " Entity type:" + entityType);
                if (entityType==="project"){
                    await FirebaseServices.deleteProjectWithId(entityId);
                }
                else{
                    await FirebaseServices.deleteProfileWithId(entityId);
                }
                
                setIsSuccess(true);
            } catch (error) {
                console.log("error deleting " + entityType + " with id: " + entityType);
            }
            setIsSubmitting(false);
        }
    }


    return (
        <div>
            <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the selected {entityType}? 
          </DialogContentText>
                </DialogContent>

                <DialogActions>

                    <Button className={classes.buttonIdle} variant="contained" onClick={handleClose}>
                        Close
                </Button>

                    <div className={classes.wrapper}>
                        <Button variant="contained" disabled={isSuccess || isSubmitting} onClick={onSubmit} className={buttonClassname}>
                            {isSuccess ? <div> Deleted! </div> : <div>Delete </div>}
                        </Button>
                        {isSubmitting && (
                            <CircularProgress size={24} className={classes.buttonProgress} />
                        )}
                    </div>


                </DialogActions>


            </Dialog>
        </div>
    );
}

export default DeleteDialog;

const useStyles = makeStyles((theme) => ({

    disabled: {},
    buttonSuccess: {
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
            color: theme.palette.secondary.main,
        },
        "&:disabled": {
            color: theme.palette.secondary.main,
        }
    },
    buttonIdle: {
        backgroundColor: theme.palette.primary.main,
        color: "#FFFFFF",
        "&:hover": {
            backgroundColor: "#2C3C4F",
            color: "white"
        }
    },
    wrapper: {
        margin: theme.spacing(1),
        position: "relative"
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }

}));