import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Switch } from "react-router-dom";
import './App.css';
import { AuthProvider } from "./Auth";
import SignIn from './Auth/Login';
import SignUp from './Auth/Register';
import ResetPassword from './Auth/ResetPassword';
import ClippedDrawer from "./ClippedDrawer";
import PrivateRoute from "./Routes/PrivateRoute";
import PublicRoute from './Routes/PublicRoute';

const theme = createMuiTheme({
  palette: {
    text: {
      primary: '#32465e',

    },
    primary: {
      main: '#32465e',
    },
    secondary: {
      main: '#4bb170',
    },
    //4bb170
    //61c19d
    // primary dark blue original: 32465e
    // orange : fb8658
  },

});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <div>
        <Helmet>
          <title>Cactus Inside
          </title>
       {/*   <link rel="icon" type="image/png" href="newLogoGreen.png" sizes="16x16" />*/}
        </Helmet>
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />

          {<Router>
            <Switch>
              <PrivateRoute path="/home" component={ClippedDrawer}></PrivateRoute>
              <PrivateRoute path="/addproject" component={ClippedDrawer}></PrivateRoute>
              <PrivateRoute path="/addprofile" component={ClippedDrawer}></PrivateRoute>
              <PrivateRoute path="/editproject/:id" component={ClippedDrawer}></PrivateRoute>
              <PrivateRoute path="/editprofile/:id" component={ClippedDrawer}></PrivateRoute>
              <PrivateRoute path="/projects" exact component={ClippedDrawer}></PrivateRoute>
              <PrivateRoute path="/projects/:id" component={ClippedDrawer}></PrivateRoute>
              <PrivateRoute path="/profiles" exact component={ClippedDrawer}></PrivateRoute>
              <PrivateRoute path="/profiles/:id" component={ClippedDrawer}></PrivateRoute>
              <PrivateRoute path="/project-submit-success" component={ClippedDrawer}></PrivateRoute>
              <PrivateRoute path="/myaccount" component={ClippedDrawer}></PrivateRoute>
              <PrivateRoute path="/faq" component={ClippedDrawer}></PrivateRoute>
              <PrivateRoute path="/terms" component={ClippedDrawer}></PrivateRoute>
              <PublicRoute path="/" exact component={SignUp}></PublicRoute>
              <PublicRoute path="/login" component={SignIn}></PublicRoute>
              <PublicRoute path="/register" component={SignUp}></PublicRoute>
              <PublicRoute path="/resetpassword" component={ResetPassword}></PublicRoute>
            </Switch>
          </Router>}
        </div>
      </AuthProvider>
    </ThemeProvider>



  );
}

export default App


