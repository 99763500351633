import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Alert from '@material-ui/lab/Alert';
import React from "react";
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import app from "../Services/firebase";

const schema = Yup.object({
  email: Yup.string()
    .email('Invalid email address')
    .required('Required'),
  password: Yup.string()
    .required('Required'),
});

const SignIn = ({ history }) => {
  const classes = useStyles();
  const [errorMsg, setErrorMsg] = React.useState();

  const { register, handleSubmit, errors } = useForm({
    validationSchema: schema
  });

  const onSubmit = async (values) => {

    try {
      await app
        .auth()
        .signInWithEmailAndPassword(values.email, values.password);
      history.push("/home");
    } catch (error) {
      setErrorMsg(error.message);
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
        </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              inputRef={register}
              name="email"
              autoComplete="email"
              error={errors.email ? true : false}
              helperText={errors.email ? (
                errors.email.message
              ) : null}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              inputRef={register}
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              error={errors.password ? true : false}
              helperText={errors.password ? (
                errors.password.message
              ) : null}
            />


            {errorMsg && <Grid item xs={12}>
              <Alert severity="error">{errorMsg}</Alert>
            </Grid>}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
          </Button>
            <Grid container>
              <Grid item xs>
                <Link href="./resetpassword" variant="body2">
                  Forgot password?
              </Link>
              </Grid>
              <Grid item>
                <Link href="./register" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
        
          </form>
          <Box mt={8}>
              <Copyright />
            </Box>
        </div>
</Container>

  );
}

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Cactus Inside
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
export default SignIn;