
import { Grid, Paper } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import * as FirebaseServices from "../../Services/firebase";
import ProjectCard from './ProjectCard.js';
import { AuthContext } from "../../Auth";

const ProjectsList = ({ history }) => {

    const classes = useStyles();
    const [projects, setProjects] = React.useState([]);
    const [isLoading, setIsLoading] = useState(true);
   // const [value, setValue] = React.useState(0);

    const userContext = useContext(AuthContext);
    const {projectListSelection, setProjectListSelection} = userContext;
   
    const handleChange = (event, newValue) => {
      //  setValue(newValue);
        setProjectListSelection(newValue);
        fetchData(newValue===0 ? "cofounder": "intern")
    };

   const fetchData = async (type) => {
        setIsLoading(true);
        try {
            setProjects(await FirebaseServices.getProjects(type));
        } catch (error) {
            alert(error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData(projectListSelection===0 ? "cofounder": "intern");
    }, [projectListSelection]);

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Container className={classes.mainContainer} maxWidth="lg">
                <Paper>
                    <Tabs
                        centered
                        value={projectListSelection}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        <Tab label="Projects looking for co-founders" {...a11yProps(0)} />
                        <Tab label="Projects looking for interns" {...a11yProps(1)} />
                    </Tabs>
                </Paper>


                <Paper  >

                    <Container >

                        <Grid style={{ marginTop: "40px", minHeight: "700px" }} container 
                            spacing={7}
                            direction="row"
                            justify={isLoading ? "center" : "flex-start"}
                            alignItems={isLoading ? "center" : "flex-start"}>

                            {
                                isLoading ? (
                                    <Grid item>
                                        <CircularProgress />
                                     </Grid>
                                )
                                    : (
                                        projects.map(project =>
                                            <Grid key={project.id} item
                                                xs={12} sm={6} md={4} lg={4} xl={3}>
                                                <Link style={{ textDecoration: 'none' }} to={`/projects/${project.id}`} >
                                                    <ProjectCard
                                                        key={project.id} title={project.title} stage={project.stage}
                                                        industry={project.industry} imageURL={project.imageURL}
                                                    />
                                                </Link>
                                            </Grid>)

                                    )}
                        </Grid>

                    </Container>

                </Paper>




            </Container>


        </div>
    );
}

export default withRouter(ProjectsList);

const useStyles = makeStyles((theme) => ({

    mainContainer: {
        marginTop: theme.spacing(20),
    },
}));

