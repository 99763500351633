import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { useForm } from "react-hook-form";
import { withRouter } from "react-router";
import * as Yup from 'yup';
import * as FirebaseServices from "../Services/firebase";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Paper from '@material-ui/core/Paper';
import Copyright from '../Copyright';

const schema = Yup.object({
  firstName: Yup.string()
    .max(15, 'Must be 15 characters or less')
    .required('Required'),
  lastName: Yup.string()
    .max(20, 'Must be 20 characters or less')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Required'),
  password: Yup.string()
    .min(6, 'Must be 6 characters or more')
    .required('Required'),
});

const SignUp = ({ history }) => {
  const classes = useStyles();
  const [errorMsg, setErrorMsg] = React.useState();

  const { register, handleSubmit, errors } = useForm({
    validationSchema: schema
  });


  const cards = [
    {
      title: "Find a co-founder",
      description: "Publish your own project or a personal profile to connect with potential co-founders.",
      imageUrl: "https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1330&q=80",
    },
    {
      title: "Join a project",
      description: "Find early-stage startups that are looking for co-founders.",
      imageUrl: "https://images.unsplash.com/photo-1586281380117-5a60ae2050cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
    },
    {
      title: "Explore resources",
      description: "Discover the most essential startup advice online – all in one place.",
      imageUrl: "https://images.unsplash.com/flagged/photo-1576045771676-7ac070c1ce72?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
    }
  ];
  const onSubmit = async (values) => {

    try {
      await
        FirebaseServices.doCreateUserWithEmailAndPassword(values.email, values.password, values.firstName, values.lastName, values.isPromotion);

      history.push("/home");
    } catch (error) {
      setErrorMsg(error.message);
    }

  }


  return (

    <Grid container component="main" className={classes.root}>
      <CssBaseline />

      <Grid item xs={false} sm={4} md={8} className={classes.image}>

        <Container className={classes.content} style={{ height: "100%", width: "100%" }}>
          <div className={classes.logoWrapper}>
            <div className={classes.landscape}>
              <img className={classes.logo} alt="" src="https://firebasestorage.googleapis.com/v0/b/cactusinside-d4aa9.appspot.com/o/assets%2FAsset%209%401s.png?alt=media&token=5730902c-6813-43f0-b682-defb76104786" />
            </div>
          </div>

          <Typography className={classes.headerText} variant="h3" component="h2">
            Meet your future <span className={classes.headerText2} >
              co-founder
         </span>
          </Typography>


          <Typography className={classes.headerText} style={{ marginTop: "20px" }} variant="subtitle1" component="h2">
            An online platform to find co-founders, join projects, and discover the best resources for founding a startup.
         </Typography>



          <Container className={classes.cardGrid} maxWidth="md">
            {/* End hero unit */}
            <Grid container spacing={4}>
              {cards.map((card) => (
                <Grid item key={card} xs={12} sm={6} md={4}>
                  <Card variant="outlined" className={classes.card}>
                    <CardMedia
                      className={classes.cardMedia}
                      image={card.imageUrl}
                      title="Image title"
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="h5" component="h2">
                        {card.title}
                      </Typography>
                      <Typography>
                        {card.description}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      {/* <Button size="small" color="primary">
                    View
                  </Button>
                  <Button size="small" color="primary">
                    Edit
          </Button> */}
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Container>

      </Grid >

      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
        </Typography>

          <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
            <Container>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="normal"
                    autoComplete="fname"
                    name="firstName"
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    inputRef={register}
                    error={errors.firstName ? true : false}
                    helperText={errors.firstName ? (
                      errors.firstName.message
                    ) : null}

                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="lname"
                    inputRef={register}
                    error={errors.lastName ? true : false}
                    helperText={errors.lastName ? (
                      errors.lastName.message
                    ) : null}

                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    inputRef={register}
                    error={errors.email ? true : false}
                    helperText={errors.email ? (
                      errors.email.message
                    ) : null}

                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    inputRef={register}
                    error={errors.password ? true : false}
                    helperText={errors.password ? (
                      errors.password.message
                    ) : null}
                  />
                </Grid>
                {errorMsg && <Grid item xs={12}>
                  <Alert severity="error">{errorMsg}</Alert>
                </Grid>}

                {<Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox
                      inputRef={register}

                      name="isPromotion" id="isPromotion" color="primary" />}
                    label="I want to receive inspiration, marketing promotions and updates via email."

                  />
                </Grid>
                }
              </Grid>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign Up
          </Button>
              <Grid container justify="flex-end">
                <Grid item>
                  <Link href="./login" variant="body2">
                    Already have an account? Sign in
              </Link>
                </Grid>
              </Grid>
              <Box mt={5}>
                <Copyright />
              </Box>
            </Container>

          </form>
        </div>


      </Grid >
    </Grid>
  );
}


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    //  backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    background: "linear-gradient(rgba(50,70,94,.7), rgba(50,70,94,.7)),url(https://source.unsplash.com/user/erondu/daily)",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  headerText: {
    color: "#DAE9FB"
  },
  headerText2: {
    color: "#4bb170"
  },

  logo: {
    maxHeight: "100%",
    maxWidth: "100%",
  },

  landscape: {
    height: "190px",
    width: "360px",
  },
  logoWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },

  homeText: {
    color: "#FFFFFF",
  },

  content: {
    flexDirection: "column",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "20px",
    alignItems: "center",
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


export default withRouter(SignUp);