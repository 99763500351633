import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import React from 'react';

export default function Copyright() {

    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "5px"  }}>
            <Typography variant="body2" style={{marginRight:"50px"}}>
            <img src="https://firebasestorage.googleapis.com/v0/b/cactusinside-d4aa9.appspot.com/o/assets%2Fyazamut.png?alt=media&token=68e3bcf4-ae0c-4ecc-8cee-dd46e3345a9a" alt=""></img>
            </Typography>
            <div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "5px"  }}>
                <Typography style={{marginRight:"5px"}} variant="body2" color="textSecondary" align="center">
                    {'Handmade with '}
                </Typography>
                <FavoriteOutlinedIcon style={{ fill: "#C72222" }}></FavoriteOutlinedIcon>
            </div>
            <Typography variant="body2" color="textSecondary" align="center" >
                <Link color="inherit" href="https://bgu.cactus.capital">
                    Cactus Inside
                </Link>{' '}
                {new Date().getFullYear()}
            </Typography>
            </div>
            <Typography variant="body2"  style={{marginLeft:"50px"}}>
            <img src="https://firebasestorage.googleapis.com/v0/b/cactusinside-d4aa9.appspot.com/o/assets%2Fbgu_logo.png?alt=media&token=c7dbf74f-4181-433a-b89a-98e119695685" alt=""></img>
            </Typography>
      
        </div>
    );
}
