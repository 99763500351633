
import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from "react-router";
import Container from '@material-ui/core/Container';
import * as FirebaseServices from "../../Services/firebase";
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';
import ProfileCard from './ProfileCard.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { AuthContext } from "../../Auth";

const ProfilesList = ({ history }) => {

    const classes = useStyles();
    const [profiles, setProfiles] = React.useState([]);
    const [isLoading, setIsLoading] = useState(true);
    //const [value, setValue] = React.useState(0);

    const userContext = useContext(AuthContext);
    const {profileListSelection, setProfileListSelection} = userContext;

    useEffect(() => {
        fetchData(profileListSelection===0 ? "cofounder": "intern");
      }, [profileListSelection]);

    
    const handleChange = (event, newValue) => {
        setProfileListSelection(newValue);
        fetchData(newValue===0 ? "cofounder": "intern")
    };

    const fetchData = async (type) => {
        setIsLoading(true);
        try {
            setProfiles(await FirebaseServices.getProfiles(type));
        } catch (error) {
            alert(error);
        }
        setIsLoading(false);
    };

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }


    return (
        <Container className={classes.mainContainer} maxWidth="lg">
            <Paper>
                <Tabs
                    centered
                    value={profileListSelection}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label="Looking to become Co-Founders" {...a11yProps(0)} />
                    <Tab label="Looking for Internship" {...a11yProps(1)} />
                </Tabs>
            </Paper>
            <Paper>
                <Container>
                    <Grid style={{ marginTop: "40px", minHeight: "700px" }}  container
                        spacing={7}
                        direction="row"
                        justify={isLoading ? "center" : "flex-start"}
                        alignItems={isLoading ? "center" : "flex-start"}>
                        {
                            isLoading ? (
                                <Grid item>
                                    <CircularProgress />
                                </Grid>)
                                :
                                (
                                    profiles.map(profile =>
                                        <Grid key={profile.id} item
                                            xs={12} sm={6} md={4} lg={4} xl={3}>
                                            <Link style={{ textDecoration: 'none' }} to={`/profiles/${profile.id}`} >
                                                <ProfileCard
                                                    key={profile.id} profile={profile}
                                                />
                                            </Link>
                                        </Grid>)
                                )}

                    </Grid>
                </Container>
            </Paper>


        </Container>
    );
}

export default withRouter(ProfilesList);

const useStyles = makeStyles((theme) => ({

    mainContainer: {
        marginTop: theme.spacing(20),
    },
}));

