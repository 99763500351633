import { Box, FormControl, FormLabel, Paper, Radio, RadioGroup } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { Fragment, useContext, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { withRouter } from "react-router";
import * as Yup from 'yup';
import { AuthContext } from "../../Auth";
import IndustryChip from "../../Components/IndustryChip";
import Copyright from '../../Copyright';
import Instructions from './Instructions';
import ProgressDialog from '../../Components/ProgressDialog'
import AvatarPicker from '../../Components/AvatarPicker';

const ProjectForm = (props) => {
  const classes = useStyles();

  const usersContext = useContext(AuthContext);
  const { currentUser } = usersContext;
  const [imageFilePath, setImageFilePath] = React.useState('');

  // Workaround for radio buttons that dont play nicely when prefilling them (Material UI + react-hook-form)
  const [industryRadio, setIndustryRadio] = React.useState(null);
  const [stageRadio, setStageRadio] = React.useState(null);
  const [typeRadio, setTypeRadio] = React.useState(null);

  const [isOpen, setIsOpen] = React.useState(false);

  const schema = Yup.object({
    title: Yup.string()
      .max(65, 'Must be 65 characters or less')
      .required('Required'),
    industry: Yup.string()
      .required('Required'),
    stage: Yup.string()
      .required('Required'),
    type: Yup.string()
      .required('Required'),
    city: Yup.string()
      .max(15, 'Must be 15 characters or less')
      .required('Required'),
    country: Yup.string()
      .max(15, 'Must be 15 characters or less')
      .required('Required'),
    description: Yup.string()
      .max(600, 'Must be 600 characters or less')
      .required('Required'),
    lookingfor: Yup.string()
      .max(400, 'Must be 400 characters or less')
      .required('Required'),
    firstName: Yup.string()
      .required('Required'),
    lastName: Yup.string()
      .required('Required'),
    email: Yup.string()
      .required('Required'),
    externalLink: Yup.string().default(''),
    linkedin: Yup.string().default(''),
  });

  const { register, handleSubmit, errors, reset, unregister, setValue } = useForm({
    validationSchema: schema,
    defaultValues: {},
  });

  const industryList = ["ecommerce", "education", "energy", "entertainment", "fashion", "financial", "health", "manufacturing", "nutrition", "realestate", "saas", "social", "other"];


  useEffect(() => {
    const fetchData = async () => {
      try {
        reset({
          firstName: props.userDetails.firstName,
          lastName: props.userDetails.lastName,
          email: props.userDetails.email,
        })
        if (props.isEditMode) {
          console.log("In edit mode...");
          setIndustryRadio(props.projectDetails.industry);
          setStageRadio(props.projectDetails.stage);
          setTypeRadio(props.projectDetails.type);
          reset({
            title: props.projectDetails.title,
            description: props.projectDetails.description,
            industry: props.projectDetails.industry,
            stage: props.projectDetails.stage,
            type: props.projectDetails.type,
            city: props.projectDetails.city,
            country: props.projectDetails.country,
            lookingfor: props.projectDetails.lookingFor,
            externalLink: props.projectDetails.externalLink,
            linkedin: props.projectDetails.linkedin,
            firstName: props.userDetails.firstName,
            lastName: props.userDetails.lastName,
            email: props.userDetails.email,
          })
        }
      } catch (error) {
        alert(error);
      }
    };

    fetchData();
  }, [currentUser, reset, props.projectDetails, props.userDetails, props.isEditMode]);

  useEffect(() => {
    register({ name: "industry" });
    register({ name: "stage" });
    register({ name: "type" });
    //register({ name: "anotherControlledField" });
    return () => unregister(["industry", "stage", "type"]);
  }, [register, unregister]);


  const handleIndustry = e => {
    const { value } = e.target;
    //set value of Radio button
    setIndustryRadio(value);
    //set value in hook-form
    setValue("industry", value);
  };

  const handleStage = e => {
    const { value } = e.target;
    //set value of Radio button
    setStageRadio(value);
    //set value in hook-form
    setValue("stage", value);
  };

  const handleType = e => {
    const { value } = e.target;
    //set value of Radio button
    setTypeRadio(value);
    //set value in hook-form
    setValue("type", value);
  };

  const onSubmit = async (values) => {
    // the last 3 parameters used for edit
    props.submitForm(values, imageFilePath, setIsOpen, industryRadio, stageRadio, typeRadio);
  }


  return (

    <Fragment>
      <Container maxWidth="md" className={classes.mainContainer}>
        <Instructions />
      </Container>
      <Container maxWidth="sm">


        <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>

          {/*///////////////////////// PAPER PART 1 //////////////////////////////*/}
          <Paper elevation={3}>
            <Container component="main" maxWidth="sm">
              <CssBaseline />
              <div className={classes.paper}>

                <Avatar className={classes.avatar} color="primary">
                  1/3
            </Avatar>
                <Typography component="h1" variant="h5" className={classes.paperTitle}>
                  Project Outline
            </Typography>

                <Grid container spacing={4}>

                  <Grid item xs={12}>

                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      defaultValue={!props.isEditMode && ""}
                      placeholder="Scalable web store for selling used computers"
                      id="title"
                      label="Project One-liner"
                      name="title"
                      inputRef={register}
                      error={errors.title ? true : false}
                      helperText={errors.title ? (
                        errors.title.message
                      ) : "A short description of your project"}
                    />
                  </Grid>



                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel required error={errors.industry} component="legend">Industry</FormLabel>
                      <RadioGroup color="primary" aria-label="industry" value={industryRadio} name="industry" onChange={handleIndustry}>
                        {industryList.map(industry => (
                          <FormControlLabel key={industry} value={industry} control={<Radio color="primary" name="industry" inputRef={register} />} label={<IndustryChip industry={industry} />} />
                        ))}
                      </RadioGroup>
                    </FormControl>

                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel required error={errors.stage} component="legend">Project Stage</FormLabel>
                      <RadioGroup color="primary" aria-label="stage" name="stage" value={stageRadio} onChange={handleStage}>
                        <FormControlLabel value="idea" control={<Radio color="primary" name="stage" inputRef={register} />} label="Idea" />
                        <FormControlLabel value="prototype" control={<Radio color="primary" name="stage" inputRef={register} />} label="Prototype" />
                        <FormControlLabel value="users" control={<Radio color="primary" name="stage" inputRef={register} />} label="Users" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel required error={errors.type} component="legend">Looking For:</FormLabel>
                      <RadioGroup color="primary" aria-label="stage" name="stage" value={typeRadio} onChange={handleType}>
                        <FormControlLabel value="cofounder" control={<Radio color="primary" name="type" inputRef={register} />} label="Co-Founder" />
                        <FormControlLabel value="intern" control={<Radio color="primary" name="type" inputRef={register} />} label="Intern" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                </Grid>


              </div>

            </Container>
          </Paper>

          {/*///////////////////////// PAPER PART 2 //////////////////////////////*/}
          <Paper elevation={3}>
            <Container component="main" maxWidth="sm">
              <CssBaseline />
              <div className={classes.paper}>

                <Avatar className={classes.avatar} color="primary">
                  2/3
            </Avatar>
                <Typography component="h1" variant="h5" className={classes.paperTitle}>
                  Project Details
            </Typography>

                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      multiline
                      variant="outlined"
                      required
                      fullWidth
                      defaultValue={!props.isEditMode && ""}
                      name="city"
                      label="City"
                      type="city"
                      id="city"
                      inputRef={register}
                      error={errors.city ? true : false}
                      helperText={errors.city ? (
                        errors.city.message
                      ) : "City you are based in"}

                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      multiline
                      variant="outlined"
                      required
                      fullWidth
                      defaultValue={!props.isEditMode && ""}
                      name="country"
                      label="Country"
                      type="country"
                      id="country"
                      inputRef={register}
                      error={errors.country ? true : false}
                      helperText={errors.country ? (
                        errors.country.message
                      ) : "Country you are based in"}

                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField

                      multiline
                      rows={5}
                      rowsMax={Infinity}
                      variant="outlined"
                      required
                      fullWidth
                      defaultValue={!props.isEditMode && ""}
                      name="description"
                      label="Project description"
                      type="description"
                      id="description"

                      inputRef={register}
                      error={errors.description ? true : false}
                      helperText={errors.description ? (
                        errors.description.message
                      ) : "Explain the problem you're solving, the solution, and your current stage."}

                    />
                  </Grid>


                  <Grid item xs={12}>
                    <TextField
                      multiline
                      rows={5}
                      rowsMax={Infinity}
                      variant="outlined"
                      required
                      fullWidth
                      defaultValue={!props.isEditMode && ""}
                      placeholder="I am looking for someone that..."
                      name="lookingfor"
                      label="Who are you looking for?"
                      type="lookingfor"
                      id="lookingfor"
                      inputRef={register}
                      error={errors.lookingfor ? true : false}
                      helperText={errors.lookingfor ? (
                        errors.lookingfor.message
                      ) : "Please elaborate on the kind of a role you're looking to fill, and the skills that person should have."}

                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      defaultValue={!props.isEditMode && ""}
                      placeholder="http://www"
                      id="externalLink"
                      label="External Link (Optional)"
                      name="externalLink"
                      helperText="Link to your website, blog, one-pager, mock-ups or anything that others might find relevant."
                      inputRef={register}

                    />
                  </Grid>

                  <Grid item xs={12} align="center">
                    <Typography variant="body1" component="h5">
                      Upload an image, it can be a logo, concept, etc..
                                     </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <AvatarPicker imageFilePath={imageFilePath} setImageFilePath={setImageFilePath} ></AvatarPicker>
                  </Grid>




                </Grid>

              </div>

            </Container>
          </Paper>


          {/*///////////////////////// PAPER PART 3 //////////////////////////////*/}
          <Paper elevation={3}>
            <Container component="main" maxWidth="sm">
              <CssBaseline />
              <div className={classes.paper}>

                <Avatar className={classes.avatar} color="primary">
                  3/3
            </Avatar>
                <Typography component="h1" variant="h5" className={classes.paperTitle}>
                  Contact Details
            </Typography>

                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="fname"
                      name="firstName"
                      variant="outlined"
                      required
                      fullWidth
                      id="firstName"
                      inputRef={register}
                      error={errors.firstName ? true : false}
                      helperText={errors.firstName ? (
                        errors.firstName.message
                      ) : null}

                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="lastName"
                      name="lastName"
                      autoComplete="lname"
                      inputRef={register}
                      error={errors.lastName ? true : false}
                      helperText={errors.lastName ? (
                        errors.lastName.message
                      ) : null}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      name="email"
                      autoComplete="email"

                      inputRef={register}
                      error={errors.email ? true : false}
                      helperText={errors.email ? (
                        errors.email.message
                      ) : "Contact email address"}

                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      defaultValue={!props.isEditMode && ""}
                      variant="outlined"
                      fullWidth
                      name="linkedin"
                      label="LinkedIn (Optional)"
                      type="linkedin"
                      id="linkedin"
                      inputRef={register}
                      error={errors.linkedin ? true : false}
                      helperText={errors.linkedin ? (
                        errors.linkedin.message
                      ) : "Linkedin profile link"}
                    />
                  </Grid>
                </Grid>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Submit
               </Button>
              </div>
            </Container>
          </Paper>

        </form>

        
        <ProgressDialog isOpen={isOpen}> </ProgressDialog>
      </Container>
      <Box mt={8}>
          <Copyright />
        </Box>
    </Fragment>
  );

}

const useStyles = makeStyles((theme) => ({
  paper2: {

  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paperTitle: {
    marginBottom: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  mainContainer: {
    marginTop: theme.spacing(15),
  },


}));


export const checkIfFilesAreCorrectType = (files) => {

  const type = files[0].type;
  console.log("File Type:" + type);
  if (!['image/jpeg', 'image/png'].includes(type)) {
    return false
  }
  else {
    return true
  }
}

export default withRouter(ProjectForm);