
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import GroupIcon from '@material-ui/icons/Group';
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import React from 'react';
import { withRouter } from "react-router";

const Faq = () => {

    const classes = useStyles();

    return (
        <Container className={classes.mainContainer} maxWidth="lg">
            <Paper>
                <Container className={classes.instructions}>
                    <List>
                        <ListItem className={classes.listItemFirst}>
                               <VisibilityOutlinedIcon fontSize="large" style={{ fill: "#4bb170" }}></VisibilityOutlinedIcon>

                            <ListItemText className={classes.listItemText} primary="1: I’m afraid that someone will steal my idea if I post it as a project."
                                secondary={`Sure, there's no way of preventing that. However, usually the most beneficial thing for founders is 
                            to actually talk about the idea to as many people as possible. You've maybe heard the phrase "ideas are cheap - what matters is the execution!"`} />

                        </ListItem>
                
                        <ListItem className={classes.listItem}>
                    
                                <GroupIcon fontSize="large" style={{ fill: "#32465e" }}></GroupIcon>
                           
                            <ListItemText className={classes.listItemText} primary="2. Who can submit a profile?"
                                secondary={`You can submit a profile if you are: Currently interested to join others' projects as a co-founder
                            Skilled in a field relevant for founding a startup (e.g. tech/business/design) with valid experience from those areas (work or personal projects)
                            Based in Israel.`} />
                        </ListItem>
                  
                        <ListItem className={classes.listItem}>
                  
                                <WhatshotIcon fontSize="large" style={{ fill: "#D82F2F" }}></WhatshotIcon>
                       
                            <ListItemText className={classes.listItemText} primary="3. Who can submit a project?"
                                secondary={`You can submit a project if you are: Looking for a co-founder to join the project (or in special cases, a C-level employee for a small team)
                            Working on a project that is scalable and realistic - and solving a real problem.
                            Based in Israel.`} />
                        </ListItem>
             
                        <ListItem className={classes.listItem}>
                         
                         <SettingsIcon fontSize="large" style={{ fill: "#6A92CF" }}></SettingsIcon>
                        
                            <ListItemText className={classes.listItemText} primary="4. Can I edit or delete my project/profile afterwards?"
                                secondary={`Of course. You can remove your project/profile by going to My Account and deleting it.`} />
                        </ListItem>
                    
                        <ListItem className={classes.listItem}>
                   
                                <HourglassEmptyOutlinedIcon fontSize="large" style={{ fill: "#D6D032" }}></HourglassEmptyOutlinedIcon>
                         
                            <ListItemText className={classes.listItemText} primary="5. I submitted a project/profile and it didn't appear in the list. What's up?"
                                secondary={`Our team will first go through your submission and make sure it's relevant. 
                                This typically takes 1-2 days. We'll let you know once it gets published, or if any changes need to be made before that!`} />
                        </ListItem>
                    </List>
                </Container>
            </Paper>

        </Container>
    );
}

export default withRouter(Faq);

const useStyles = makeStyles((theme) => ({

    mainContainer: {
        marginTop: theme.spacing(20),
    },
    instructions: {
        width: '100%',
        maxWidth: 1060,
        backgroundColor: theme.palette.background.paper,
    },

    listItemText: {
        marginLeft: 40,

    }
}));

