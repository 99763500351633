import { Paper } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React, { Fragment, useEffect } from 'react';
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import * as FirebaseServices from "../Services/firebase";
import DeleteDialog from './DeleteDialog';


const MyProjects = () => {
    const classes = useStyles();
    const history = useHistory();
    const [projects, setProjects] = React.useState(null);
    const [profiles, setProfiles] = React.useState(null);

    const [selectedEntityId, setSelectedEntityId] = React.useState(null);
    const [entityType, setEntityType] = React.useState(null);

    const [isOpen, setIsOpen] = React.useState(false);
    
    
      const handleDialogClose = () => {
        setIsOpen(false);
      };

      
    const handleDeleteProject = (entityId) => {
        console.log("handling click delete");
        setSelectedEntityId(entityId);
        setEntityType("project");
        setIsOpen(true);
    };
          
    const handleDeleteProfile = (entityId) => {
        console.log("handling click delete");
        setSelectedEntityId(entityId);
        setEntityType("profile");
        setIsOpen(true);
    };

    // Prefilling the user details form with first/last name and email.
    useEffect(() => {
        const fetchData = async () => {

            try {
                setProjects(await FirebaseServices.getUserProjects());
                setProfiles(await FirebaseServices.getUserProfiles());

            } catch (error) {
                console.log("error getting projects and/or profiles");
            }

        };

        fetchData();
    }, []);


    const handleEditProfile = (value) => () => {
        console.log("editing profile ", value)
        history.push(`/editprofile/${value}`);
    };
    const handleEditProject = (value) => () => {
        console.log("editing project ", value)
        history.push(`/editproject/${value}`);
    };


    return (
        <Fragment>
            <Container maxWidth="md" className={classes.mainContainer}>

                {/*///////////////////////// PAPER PART 1 //////////////////////////////*/}
                <Paper elevation={3}>
                    <Container component="main" maxWidth="sm">
                        <CssBaseline />
                        <div className={classes.paper}>

                            <List className={classes.root}>
                                <ListSubheader>{`Active Projects`}</ListSubheader>

                                {projects && (
                                    projects.map((project) => {
                                        const labelId = `checkbox-list-label-${project.title}`;

                                        return (
                                            <ListItem key={project.id} role={undefined} dense button onClick={handleEditProject(project.id)} >
                                                <ListItemIcon>
                                                    <EditIcon />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={`${project.title}`} />
                                                <ListItemSecondaryAction>
                                                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteProject(project.id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        );
                                    }))

                                }

                                <ListSubheader>{`Active Profiles`}</ListSubheader>

                                {profiles && (
                                    profiles.map((profile) => {
                                        const labelId = `checkbox-list-label-${profile.firstName}`;

                                        return (
                                            <ListItem key={profile.id} role={undefined} dense button onClick={handleEditProfile(profile.id)} >
                                                <ListItemIcon>
                                                    <EditIcon />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={`${profile.firstName} ${profile.lastName}`} />
                                                <ListItemSecondaryAction>
                                                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteProfile(profile.id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        );
                                    }))

                                }

                            </List>


                        </div>
                    </Container>
                </Paper>

            </Container>
            {selectedEntityId && <DeleteDialog isOpen={isOpen} handleClose={handleDialogClose} entityId={selectedEntityId} entityType={entityType} >
            </DeleteDialog>}

        </Fragment>
    );

}


const useStyles = makeStyles((theme) => ({

    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },


    paper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    paperTitle: {
        marginBottom: theme.spacing(2),
    },

    mainContainer: {
        marginTop: theme.spacing(15),
    },


}));


export default withRouter(MyProjects);