import React, { useEffect, useState } from "react";
import app from "./Services/firebase.js";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [profileListSelection, setProfileListSelection] = useState(0);
   const [projectListSelection, setProjectListSelection] = useState(0);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    app.auth().onAuthStateChanged((user) => {
      console.log("AUTH CHANGED");
      setCurrentUser(user)
      setPending(false)
    });
  }, []);

  const userContext = {
    currentUser,
    profileListSelection,
    projectListSelection,
    setProfileListSelection,
    setProjectListSelection,
  };

  if(pending){
    return <>Loading...</>
  }

  return (
    <AuthContext.Provider
      value={
        userContext
      }
    >
      {children}
    </AuthContext.Provider>
  );
};