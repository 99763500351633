import Uppy from '@uppy/core'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import { DragDrop, StatusBar } from '@uppy/react'
import '@uppy/status-bar/dist/style.css'
import Transloadit from '@uppy/transloadit'
import React from 'react'

const AvatarPicker = (props) => {
    //  const [avatarURL, setAvatarURL] = React.useState('');

    const uppy = new Uppy({
        meta: { type: 'avatar' },
        restrictions: {
            maxNumberOfFiles: 1,
            maxFileSize: 5000000,
            allowedFileTypes: ['image/*']
        },
        autoProceed: true
    })

    uppy.use(Transloadit, {
        service: 'https://api2.transloadit.com',
        params: {
            auth: { key: 'a811a32846454456bc531b6da201e5ef' },
            template_id: '022500a89f50441c97f1de93563d15a4'
        },
        waitForEncoding: true,
        waitForMetadata: false,
        importFromUploadURLs: false,
        alwaysRunAssembly: false,
        signature: null,
        fields: {},
        limit: 0
    })


    uppy.on('transloadit:result', (stepName, result, assmembly) => {
        uppy.info(`Skipping file because it has no type`, 'error', 500)
        console.log(result);
        console.log(result.url);
        //  setAvatarURL(result.url);
        props.setImageFilePath(result.url)
        console.log(stepName);
        console.log(assmembly);
    })
    uppy.on('restriction-failed', (file, error) => {
        console.log(error.message);
        // do some customized logic like showing system notice to users
    })



    return (
        <div style={{textAlign: "center"}}>
         
            {props.imageFilePath ? <img src={props.imageFilePath} alt="No avatar has been set"/> : <div>No selected image</div>}

            <StatusBar
                uppy={uppy}
                hideUploadButton
                hideAfterFinish={false}
                showProgressDetails
            />
            <DragDrop
                uppy={uppy}
                locale={{
                    strings: {
                        // Text to show on the droppable area.
                        // `%{browse}` is replaced with a link that opens the system file selection dialog.
                        dropHereOr: 'Drop here or %{browse}',
                        // Used as the label for the link that opens the system file selection dialog.
                        browse: 'browse'
                    }
                }}
            />

        </div>
    )
}

export default AvatarPicker;