import React, { useContext, useEffect } from 'react';
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../Auth";
import * as FirebaseServices from "../../Services/firebase";
import ProfileForm from './ProfileForm';

const EditProfile = ({ match }) => {
  const history = useHistory();

  const usersContext = useContext(AuthContext);
  const {currentUser } = usersContext;
  const [userDetails, setUserDetails] = React.useState(null);
  const [profile, setProfile] = React.useState(null);

  // Prefilling the user details form with first/last name and email.
  useEffect(() => {
    const fetchData = async () => {
      //  setIsLoading(true);
      try {
        console.log("getting profile details");
        const profileDetails =  await FirebaseServices.getFullProfileDetails(match.params.id);
        setProfile(profileDetails);
        setUserDetails({
          firstName: profileDetails.firstName,
          lastName: profileDetails.lastName,
          email: profileDetails.email,
        });
      } catch (error) {
        alert(error);
      }
      ///  setIsLoading(false);
    };

    fetchData();
  }, [currentUser, match.params.id]);



  const onSubmit = async (values, setIsOpen, imageFilePath, skills, industries, typeValue) => {

    console.log(values);
    console.log(values.linkedin);
    setIsOpen(true);
    try {
   //   const originalimage = values.image[0];
    //  const imageAsFile = await Utils.getCompressedImage(originalimage);
   //   const imgDownloadURL = await FirebaseServices.uploadFile(currentUser.uid, imageAsFile);
   const imgDownloadURL = imageFilePath;
      await FirebaseServices.updateProfileWithId(currentUser.uid, match.params.id, values.firstName, values.lastName, values.email, values.linkedin,
        values.city, values.country, skills, industries, values.lookingfor, values.externalLink, values.publishReason, imgDownloadURL, typeValue);
      setIsOpen(false);
      history.push("/project-submit-success");
    } catch (error) {
      setIsOpen(false);
      console.log(error);
    }
  }

  return (
    profile && userDetails && (<ProfileForm isEditMode={true} userDetails={userDetails} profileDetails={profile} submitForm={onSubmit}></ProfileForm>)
   
  );

}

export default withRouter(EditProfile);