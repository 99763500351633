import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import IndustryChip from '../../Components/IndustryChip';
import StageChip from '../../Components/StageChip';
import WhatshotIcon from '@material-ui/icons/Whatshot';


export default function ProjectCard({ title, stage, industry, imageURL }) {
  const classes = useStyles();

  return (
    <Paper className={classes.root} >
      <CardActionArea className={classes.root}>

        <div className={classes.top} style={{ overflow: 'hidden ' }}>

          {imageURL ?
            <div style={{ height: "50px", position: "relative", backgroundImage: `url("${imageURL}")`, backgroundSize: "cover", backgroundPosition: "center" }}>
              <div className={classes.backgroundFilterAfter} />
            </div>
            :
            <div className={classes.altMedia}></div>
          }

        </div>

        <Container className={classes.overlays}>
          <Card >
            {imageURL ? <CardMedia
              className={classes.overlayLogo}
              image={imageURL}
              title="Contemplative Reptile"
            />
              :
              <div className={classes.overlayLogo}><WhatshotIcon className={classes.overlayLogoIcon} /> </div>}
          </Card>
          <IndustryChip industry={industry} />
        </Container>

        <Container className={classes.mid}>
          <Typography variant="body1" component="p">
            {title}
          </Typography>
        </Container>

        <Container className={classes.bottom}>
          <StageChip stage={stage}></StageChip>
        </Container>

      </CardActionArea>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: 300,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "stretch",
  },

  cardMedia: {
    flex: "1",
  },

  top: {
    flex: 2,
    display: "flex",
    flexDirection: "column",
  },

  mid: {
    flex: "6",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  bottom: {
    flex: "1"
  },

  overlays: {
    display: 'flex',
    position: 'absolute',
    justifyContent: 'space-between',
    alignItems: 'center',
    left: "0%",
    top: "50px",
    transform: "translate(0%, -50%)",
  },

  overlayLogo: {
    height: theme.spacing(7),
    width: theme.spacing(7),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  overlayLogoIcon: {
    height: theme.spacing(7) / 1.7,
    width: theme.spacing(7) / 1.7,
  },

  altMedia: {
    background: "linear-gradient(90deg, rgba(50,70,94,1) 18%, rgba(64,95,119,1) 50%, rgba(73,102,135,1) 100%)",
    height: "50px",
  },


  backgroundFilterAfter: {
    WebkitBackdropFilter: "blur(5px)", /* Use for Safari 9+, Edge 17+ (not a mistake) and iOS Safari 9.2+ */
    backdropFilter: "blur(5px)", /* Supported in Chrome 76 */
    display: "block",
    position: "absolute",
    width: "100%",
    height: "100%",
  },

}));
