import { Box, Paper } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import React, { Fragment, useContext, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { withRouter } from "react-router";
import * as Yup from 'yup';
import { AuthContext } from "../Auth";
import Copyright from "../Copyright";
import * as FirebaseServices from "../Services/firebase";
import MyProjects from "./MyProjects";


const MyAccount = () => {
  const classes = useStyles();
  const [errorMsg, setErrorMsg] = React.useState(null);
  const [successMsg, setSuccessMsg] = React.useState(null);
  const [errorPassMsg, setErrorPassMsg] = React.useState(null);
  const [successPassMsg, setSuccessPassMsg] = React.useState(null);
  const usersContext = useContext(AuthContext);
  const {currentUser } = usersContext;

  const schema = Yup.object({
    firstName: Yup.string()
      .required('First name is required'),
    lastName: Yup.string()
      .required('Last name is required'),
    email: Yup.string()
      .required('Email is required'),
  });
  const { register, handleSubmit, errors, reset } = useForm({
    validationSchema: schema
  });
  
  // Prefilling the user details form with first/last name and email.
  useEffect(() => {
    const fetchData = async () => {
      //  setIsLoading(true);
      try {
        const user = await FirebaseServices.getUserDetails(currentUser.uid);
        console.log(user);
        reset({
          firstName: user.firstName,
          lastName: user.lastName,
          email: currentUser.email,
        })
      } catch (error) {
        setErrorMsg(error.message);
      }
      ///  setIsLoading(false);
    };

    fetchData();
  }, [currentUser, reset]);




  const onSubmit = async (values) => {

    try {
      await FirebaseServices.updateUserDetails(values.firstName, values.lastName, values.email);
      setSuccessMsg("Details updated successfully");
      console.log("success");
    } catch (error) {
      setErrorMsg(error.message);
    }
  }



  ///// Second form for changing password
  const schemaPassForm = Yup.object({
    currentPassword: Yup.string().required('Current password is required'),
    password: Yup.string().required('Password is required'),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
  });

  const { register: registerPassForm, handleSubmit: handleSubmitPassForm, errors: errorsPassForm } = useForm({
    validationSchema: schemaPassForm
  });


  const onSubmitChangePassword = async (values) => {

    try {
      setSuccessPassMsg(null);
      setErrorPassMsg(null);
      console.log("submitting change password");
      await FirebaseServices.updateUserPassword(values.currentPassword, values.password);
      setSuccessPassMsg("Password updated successfully");
    } catch (error) {
      setErrorPassMsg(error.message);
    }
  }



  return (
    <Fragment>
      <Container maxWidth="lg" className={classes.mainContainer}>


        <Grid container
          spacing={7}
          direction="row"
          justify="flex-start"
          alignItems="center">

          <Grid key="primarycard" item xs={12} sm={12} md={6} lg={6} xl={6}>

            {/*///////////////////////// PAPER PART 1 //////////////////////////////*/}
            <Paper elevation={3}>
              <Container component="main" maxWidth="md">
                <CssBaseline />
                <div className={classes.paper}>

                  <Avatar className={classes.avatar} color="primary">

                  </Avatar>
                  <Typography component="h1" variant="h5" className={classes.paperTitle}>
                    Account Information
                </Typography>

                  <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>

                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoComplete="fname"
                          name="firstName"
                          variant="outlined"
                          required
                          fullWidth
                          id="firstName"
                          inputRef={register}
                          error={errors.firstName ? true : false}
                          helperText={errors.firstName ? (
                            errors.firstName.message
                          ) : "First name"}

                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="lastName"
                          name="lastName"
                          autoComplete="lname"
                          inputRef={register}
                          error={errors.lastName ? true : false}
                          helperText={errors.lastName ? (
                            errors.lastName.message
                          ) : "Last name"}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="email"
                          name="email"
                          autoComplete="email"

                          inputRef={register}
                          error={errors.email ? true : false}
                          helperText={errors.email ? (
                            errors.email.message
                          ) : "Contact email address"}

                        />
                      </Grid>
                      <Grid container item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                        >
                          Update Details
               </Button>
                      </Grid>

                      {errorMsg && <Grid item xs={12}>
                        <Alert severity="error">{errorMsg}</Alert>
                      </Grid>}
                      {successMsg && <Grid item xs={12}>
                        <Alert severity="success">{successMsg}</Alert>
                      </Grid>}


                    </Grid>
                  </form>
                  <form className={classes.form} noValidate onSubmit={handleSubmitPassForm(onSubmitChangePassword)}>

                    <Grid container spacing={4} style={{ marginTop: "20px" }}>
                      <Grid item xs={12} sm={12}>
                        <Divider />
                      </Grid>


                      <Grid item xs={12} sm={12}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          name="currentPassword"
                          label="Current password"
                          type="password"
                          id="currentPassword"
                          inputRef={registerPassForm}
                          error={errorsPassForm.currentPassword ? true : false}
                          helperText={errorsPassForm.currentPassword ? (
                            errorsPassForm.currentPassword.message
                          ) : "Current password"}
                        />
                      </Grid>


                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          name="password"
                          label="New password"
                          type="password"
                          id="password"
                          inputRef={registerPassForm}
                          error={errorsPassForm.password ? true : false}
                          helperText={errorsPassForm.password ? (
                            errorsPassForm.password.message
                          ) : "New password"}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          name="passwordConfirmation"
                          label="Confirm password"
                          type="password"
                          id="passwordConfirmation"
                          inputRef={registerPassForm}
                          error={errorsPassForm.passwordConfirmation ? true : false}
                          helperText={errorsPassForm.passwordConfirmation ? (
                            errorsPassForm.passwordConfirmation.message
                          ) : "Confirm new password"}

                        />
                      </Grid>


                      {errorPassMsg && <Grid item xs={12}>
                        <Alert severity="error">{errorPassMsg}</Alert>
                      </Grid>}
                      {successPassMsg && <Grid item xs={12}>
                        <Alert severity="success">{successPassMsg}</Alert>
                      </Grid>}


                      <Grid container item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                        >
                          Update Password
               </Button>
                      </Grid>


                    </Grid>

                  </form>
                </div>
              </Container>
            </Paper>


          </Grid>

          <Grid key="secondarycard" item xs={12} sm={12} md={6} lg={6} xl={6}>

            <MyProjects></MyProjects>
          </Grid>
        </Grid>


        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </Fragment>
  );

}

const useStyles = makeStyles((theme) => ({

  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paperTitle: {
    marginBottom: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    //margin: theme.spacing(3, 0, 2),
  },

  mainContainer: {
    marginTop: theme.spacing(7),
  },

  formControl: {
    margin: theme.spacing(0),
    minWidth: "100%",
    maxWidth: "100%",
  },

}));


export default withRouter(MyAccount);