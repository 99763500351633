import { CardActions, Grid } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/Person';
import React, { useEffect } from 'react';
import ContactDialog from '../../Components/ContactDialog';
import IndustryChip from '../../Components/IndustryChip';
import * as FirebaseServices from "../../Services/firebase";

export default function ProfileDetail({ match }) {
  const classes = useStyles();
  const [profile, setProfile] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleDialogOpen = () => {
    setIsOpen(true);
  };

  const handleDialogClose = () => {
    setIsOpen(false);
  };


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        setProfile(await FirebaseServices.getProfileDetails(match.params.id));
      } catch (error) {
        alert(error);
      }
      setIsLoading(false);

    };

    fetchData();
  }, [match.params.id]);


  return (
    <Container className={classes.mainContainer} maxWidth="lg">


      {

        isLoading ? (
          <div
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          >
            <CircularProgress />
          </div>
        )
          :
          (

            <Grid container
              spacing={7}
              direction="row"
              justify="flex-start"
              alignItems="center">

              <Grid key="primarycard" item xs={12} sm={12} md={7} lg={7} xl={7}>

                <Paper className={classes.primaryCard} >


                  {profile.imageURL ?
                    <div style={{ height: "80px", position: "relative", backgroundImage: `url("${profile.imageURL}")`, backgroundSize: "cover", backgroundPosition: "center" }}>
                      <div className={classes.backgroundFilterAfter} />
                    </div>
                    :
                    <div className={classes.altMedia}></div>
                  }


                  <Container className={classes.overlays}>
                    {profile.imageURL ? <Avatar alt="Remy Sharp" src={profile.imageURL} className={classes.overlayLogo} />
                      :
                      <Avatar alt="Remy Sharp" className={classes.overlayLogo}><PersonIcon className={classes.overlayLogoIcon}></PersonIcon></Avatar>}

                  </Container>


                  <Container className={classes.mid} >
                    <Typography variant="h4" >
                      {`${profile.firstName} ${profile.lastName}`}
                    </Typography>

                    <div className={classes.paperRow}>
                      <Typography variant="body2" component="p">
                        {profile.city}, {profile.country}
                      </Typography>
                      <Typography variant="body2" >

                        {profile.linkedin ?
                          <Link href={"//" + profile.linkedin}>
                            <img alt="linkedin" style={{ marginTop: "2px", opacity: "0.5", height: '32px', width: '32px' }} src="https://firebasestorage.googleapis.com/v0/b/cactusinside-211a8.appspot.com/o/assets%2Flinkedin.png?alt=media&token=6e380070-f4a0-490a-a146-eed4691fda44"></img>
                          </Link>
                          : ""}
                      </Typography>
                    </div>

                  </Container>

                  <Container className={classes.desc} >
                    <div>
                      <Typography variant="overline" >
                        Interested in
                    </Typography>
                      <Typography variant="body2" component="p">
                        {profile.lookingFor}
                      </Typography>
                    </div>

                    <div style={{ marginTop: "2%", display: "flex", flexDirection: "row" }}>
                      {profile.industries.map((industry) => (
                        <IndustryChip key={industry} style={{ marginRight: "10px" }} industry={industry}></IndustryChip>
                      ))}
                    </div>
                  </Container>


                  <Container className={classes.bottom}>
                    <div>
                      <Typography variant="overline" >
                        Skills
                    </Typography>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                   
                          <Typography variant="body2" >
                            {profile.skills.join(', ')}
                          </Typography>

                      </div>
                    </div>
                  </Container>

                </Paper>

              </Grid>


              <Grid key="secondarycard" item xs={12} sm={12} md={5} lg={5} xl={5}>

                <Card className={classes.secondaryCard} >

                  <CardContent>
                    <Typography variant="overline" component="p">
                      Contact Profile
                    </Typography>
                    <br></br>

                    <Typography variant="body2" component="p">Explain what you're working on and how you'd like to involve the person. Please, contact profiles only about relevant co-founder opportunities.</Typography>

                  </CardContent>

                  <CardActions style={{ justifyContent: 'center' }}>
                    <Button variant="contained" color="primary" onClick={handleDialogOpen}>
                      Contact {profile.firstName}
                  </Button>
                  </CardActions>
                </Card>

              </Grid>


            </Grid>
          )}

      <ContactDialog isOpen={isOpen} handleClose={handleDialogClose} entityId={profile.id} entityType="profile">
      </ContactDialog>

    </Container>
  );
}


const useStyles = makeStyles((theme) => ({
  primaryCard: {
    minHeight: 450,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "stretch",

  },
  paperRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: "0 0 0 0 ",
  },

  secondaryCard: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "stretch",
  },


  mid: {
 
    marginTop: "8%",
    flex: "1",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },

  desc: {
    flex: "2",
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    alignItems: "space-between",
  },

  bottom: {
    flex: "1"
  },
  overlays: {
    display: 'flex',
    position: 'absolute',
    justifyContent: 'flex-start',
    alignItems: 'center',
    left: "5%",
    top: "80px",
    transform: "translate(0%, -50%)",
  },

  altMedia: {
    background: "linear-gradient(90deg, rgba(50,70,94,1) 18%, rgba(64,95,119,1) 50%, rgba(73,102,135,1) 100%)",
    height: "80px",
  },

  overlayLogoIcon: {
    height: theme.spacing(7),
    width: theme.spacing(7),

  },

  overlayLogo: {
    height: theme.spacing(11),
    width: theme.spacing(11),
    display: "flex",
    justifyContent: "center",
    borderStyle: "solid",
    borderColor: "#FFFFFF",
    alignItems: "center",
  },
  mainContainer: {
    marginTop: theme.spacing(20),
  },

  backgroundFilterAfter: {
    WebkitBackdropFilter: "blur(5px)", /* Use for Safari 9+, Edge 17+ (not a mistake) and iOS Safari 9.2+ */
    backdropFilter: "blur(5px)", /* Supported in Chrome 76 */
    display: "block",
    position: "absolute",
    width: "100%",
    height: "100%",
  },
}));
