import Chip from '@material-ui/core/Chip';
import React from 'react';

export default function IndustryChip(props) {

    var customChip;
    switch (props.industry) {
        case 'ecommerce':
            customChip = <Chip color="primary" style={{ backgroundColor: '#D08535', ...props.style }} size="small" label="E-COMMERCE"></Chip>
            break;
        case 'education':
            customChip = <Chip color="primary" style={{ backgroundColor: '#DBD114', ...props.style }} size="small" label="EDUCATION"></Chip>
            break;
        case 'entertainment':
            customChip = <Chip color="primary" style={{ backgroundColor: '#7B57B3', ...props.style }} size="small" label="ENTERTAINMENT"></Chip>
            break;
        case 'energy':
            customChip = <Chip color="primary" style={{ backgroundColor: '#4aa457', ...props.style }} size="small" label="ENERGY"></Chip>
            break;
        case 'fashion':
            customChip = <Chip color="primary" style={{ backgroundColor: '#A7538F', ...props.style }} size="small" label="FASHION"></Chip>
            break;
        case 'financial':
            customChip = <Chip color="primary" style={{ backgroundColor: '#3D4B67', ...props.style }} size="small" label="FINANCIAL"></Chip>
            break;

        case 'health':
            customChip = <Chip color="primary" style={{ backgroundColor: '#3e80cb', ...props.style }} size="small" label="HEALTH"></Chip>
            break;

        case 'manufacturing':
            customChip = <Chip color="primary" style={{ backgroundColor: '#965d46', ...props.style }} size="small" label="MANUFACTURING"></Chip>
            break;

        case 'nutrition':
            customChip = <Chip color="primary" style={{ backgroundColor: '#1a8861', ...props.style }} size="small" label="NUTRITION"></Chip>
            break;

        case 'realestate':
            customChip = <Chip color="primary" style={{ backgroundColor: '#3e80cb', ...props.style }} size="small" label="REAL-ESTATE"></Chip>
            break;

        case 'saas':
            customChip = <Chip color="primary" style={{ backgroundColor: '#8e315e', ...props.style }} size="small" label="SAAS"></Chip>
            break;
        case 'social':
            customChip = <Chip color="primary" style={{ backgroundColor: '#258796', ...props.style }} size="small" label="SOCIAL"></Chip>
            break;
        case 'other':
            customChip = <Chip color="primary" style={{ backgroundColor: '#c0c0c0', ...props.style }} size="small" label="OTHER"></Chip>
            break;
        default:
            customChip = <Chip color="primary" style={{ backgroundColor: '#c0c0c0', ...props.style }} size="small" label="OTHER"></Chip>

    }

    return customChip;

}