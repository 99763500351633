import Avatar from '@material-ui/core/Avatar';
import CardActionArea from '@material-ui/core/CardActionArea';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BuildIcon from '@material-ui/icons/Build';
import LinkIcon from '@material-ui/icons/Link';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PersonIcon from '@material-ui/icons/Person';
import React from 'react';

export default function ProfileCard({ profile }) {
  const classes = useStyles();

  return (
    <Paper className={classes.root} >
      <CardActionArea className={classes.root}>

        <div className={classes.top} style={{overflow: 'hidden ' }}>

          {profile.imageURL ?
            <div style={{ height: "50px", position: "relative", backgroundImage: `url("${profile.imageURL}")`, backgroundSize: "cover", backgroundPosition: "center" }}>
              <div className={classes.backgroundFilterAfter} />
            </div>
            :
            <div className={classes.altMedia}></div>
          }

        </div>


        <Container className={classes.overlays}>

          {profile.imageURL ? <Avatar alt="Remy Sharp" src={profile.imageURL} className={classes.overlayLogo} />
            :
            <Avatar alt="Remy Sharp" className={classes.overlayLogo}><PersonIcon className={classes.overlayLogoIcon}></PersonIcon></Avatar>
            }

        </Container>

        <Container className={classes.mid}>
          <Typography variant="h6" component="p">
            {profile.firstName}  {profile.lastName}
         </Typography>
 
        </Container>

        <Divider style={{ marginTop: "5px", marginBottom: "5px"}} variant="middle" />

        <Container className={classes.midDetails}>

          <div className={classes.paperRow}>
            <LocationOnIcon style={{ marginRight: "5px", opacity: "0.5", height: '16px', width: '16px' }} />
            <Typography variant="body2" component="p">

              {profile.city}, {profile.country}
            </Typography>
          </div>

          <div className={classes.paperRow}>
            <BuildIcon style={{ marginRight: "5px", opacity: "0.5", height: '16px', width: '16px' }} />

            <Typography variant="body2" component="p">
              {profile.skills.join(', ')}
            </Typography>
          </div>
        </Container>

        <Container className={classes.bottom}>

          {profile.linkedin ?
           // <Link href={profile.linkedin}>
              <img alt="linkedin" style={{ opacity: "0.5", height: '32px', width: '32px', transform: "translate(-25%, 0%)", }} src="https://firebasestorage.googleapis.com/v0/b/cactusinside-211a8.appspot.com/o/assets%2Flinkedin.png?alt=media&token=6e380070-f4a0-490a-a146-eed4691fda44"></img>
            //</Link>
            : ""}

          <LinkIcon style={{ opacity: "0.5", height: '32px', width: '32px', transform: "translate(-25%, 0%)", }}></LinkIcon>

        </Container>

      </CardActionArea>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: 300,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "stretch",
  },

  cardMedia: {
    flex: "1",
  },

  paperRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    margin: "5px 0 0 0 ",
  },

  top: {
    flex: 2,
    display: "flex",
    flexDirection: "column",
  },

  mid: {
    
       flexGrow: "0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },

  midDetails: {
    flex: "2",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },

  bottom: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  overlays: {
    display: 'flex',
    position: 'absolute',
    justifyContent: 'space-between',
    alignItems: 'center',
    left: "0%",
    top: "50px",
    transform: "translate(0%, -50%)",
  },

  overlayLogo: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    borderStyle: "solid",
    borderColor: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  overlayLogoIcon: {
    height: theme.spacing(7) / 1.7,
    width: theme.spacing(7) / 1.7,
  },

  altMedia: {
    background: "linear-gradient(90deg, rgba(50,70,94,1) 18%, rgba(64,95,119,1) 50%, rgba(73,102,135,1) 100%)",
    height: "50px",
  },


  backgroundFilterAfter: {
    WebkitBackdropFilter: "blur(5px)", /* Use for Safari 9+, Edge 17+ (not a mistake) and iOS Safari 9.2+ */
    backdropFilter: "blur(5px)", /* Supported in Chrome 76 */
    display: "block",
    position: "absolute",
    width: "100%",
    height: "100%",
  },

}));
