import React, { useContext, useEffect } from 'react';
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../Auth";
import * as FirebaseServices from "../../Services/firebase";
import ProjectForm from './ProjectForm';

const AddProject = () => {
  const history = useHistory();
  const usersContext = useContext(AuthContext);
  const {currentUser } = usersContext;
  const [userDetails, setUserDetails] = React.useState(null);

  // Prefilling the user details form with first/last name and email.
  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await FirebaseServices.getUserDetails(currentUser.uid);
        console.log(user);
        setUserDetails({
          firstName: user.firstName,
          lastName: user.lastName,
          email: currentUser.email,
        });
      } catch (error) {
        alert(error);
      }
    };

    fetchData();
  }, [currentUser]);



  const onSubmit = async (values, imageFilePath, setIsOpen) => {
    console.log("Adding new project");
    try {
     setIsOpen(true);
      //const originalimage = values.image[0];
      //const imageAsFile = await Utils.getCompressedImage(originalimage);
      //const imgDownloadURL = await FirebaseServices.uploadFile(currentUser.uid, imageAsFile);
      const imgDownloadURL = imageFilePath;
      await FirebaseServices.addProject(currentUser.uid, values.title, values.description, values.lookingfor,
        values.externalLink, values.industry, values.stage, imgDownloadURL, values.city, values.country, values.firstName, values.lastName, values.email, values.linkedin, values.type);
     setIsOpen(false);
      history.push("/project-submit-success");
    } catch (error) {
      console.log(error);
      setIsOpen(false);
    }
  }
  return (
    userDetails && (<ProjectForm isEditMode={false} userDetails={userDetails} submitForm={onSubmit}></ProjectForm>)
  );
}

export default withRouter(AddProject);