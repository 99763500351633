import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import IndustryChip from '../../Components/IndustryChip';

export default function ExtraDetailsPaper(props) {

    const classes = useStyles();

    return (

        <Paper className={classes.paper} elevation={1} style={props.style}>
            <Container className={classes.paperRow}>
                <Typography variant="body2" className={classes.paperItemFirst}>Industry</Typography>
                <div className={classes.paperItemSecond}>{<IndustryChip industry={props.industry}></IndustryChip>}</div>
            </Container>
            <Container className={classes.paperRow}>
                <Typography variant="body2" className={classes.paperItemFirst}>Stage</Typography>
                <Typography variant="body2" className={classes.paperItemSecond}>{props.stage.charAt(0).toUpperCase() + props.stage.slice(1)}</Typography>
            </Container>
            <Container className={classes.paperRow}>
                <Typography variant="body2" className={classes.paperItemFirst}>Based in</Typography>
                <Typography variant="body2" className={classes.paperItemSecond}>{props.location}</Typography>
            </Container>
            <Container className={classes.paperRow}>
                <Typography variant="body2" className={classes.paperItemFirst}>Published</Typography>
                <Typography variant="body2" className={classes.paperItemSecond}>{props.createdDate}</Typography>
            </Container>
        </Paper>

    )


}

const useStyles = makeStyles((theme) => ({
    paper: {
     
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f8f9fb',
     },
    paperRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: "0 0 0 0 ",
        padding: "5px 15px 5px 15px",
    },

    paperItemFirst: {
        color: "grey",
        marginRight: "10px",
     
      },
    paperItemSecond: {
     
            textAlign: "right",
            marginLeft: "auto",
      },

}));