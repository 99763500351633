import { Box, Paper } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import GroupIcon from '@material-ui/icons/Group';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import React from 'react';
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Copyright from './Copyright';

const Home = () => {
  const classes = useStyles();

  const cards = [
    {
      title: "Browse Projects",
      description: "Find early-stage startups that are looking for co-founders",
      imageUrl: "https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1330&q=80",
      icon: <WhatshotIcon style={{ width: "70px", height: "70px" }} />,
      link: "/projects",
    },
    {
      title: "Browse Profiles",
      description: "Find the right co-founder profile for your project",
      imageUrl: "https://images.unsplash.com/photo-1586281380117-5a60ae2050cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
      icon: <GroupIcon style={{ width: "70px", height: "70px" }} />,
      link: "/profiles",
    },
    {
      title: "Submit Project",
      description: "Create a project and publish it on the platform",
      imageUrl: "https://images.unsplash.com/flagged/photo-1576045771676-7ac070c1ce72?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
      icon: <ControlPointIcon style={{ width: "70px", height: "70px" }} />,
      link: "/addproject",
    },
    {
      title: "Submit Profile",
      description: "Create a profile and publish it on the platform",
      imageUrl: "https://images.unsplash.com/flagged/photo-1576045771676-7ac070c1ce72?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
      icon: <ControlPointIcon style={{ width: "70px", height: "70px" }} />,
      link: "/addprofile",
    }
  ];

  return (

    <Container maxWidth="lg" className={classes.mainContainer}>

      <Paper elevation={3} className={classes.paper} >


        <Container className={classes.cardGrid} maxWidth="md">


          {/* End hero unit */}
          <Grid container spacing={4}>
            {cards.map((card) => (
              <Grid item key={card} xs={12} sm={6} md={6}>
                <Link style={{ textDecoration: 'none' }} to={card.link}>
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardContent className={classes.cardContent}>
                        {card.icon}
                        <Typography gutterBottom variant="h5" component="h2" style={{ marginTop: "10px" }}>
                          {card.title}
                        </Typography>
                        <Typography>
                          {card.description}
                        </Typography>

                      </CardContent>
                      <CardActions>
                        {/* <Button size="small" color="primary">
                    View
                  </Button>
                  <Button size="small" color="primary">
                    Edit
          </Button> */}
                      </CardActions>
                    </CardActionArea>

                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Paper>
      <Box mt={8}>
        <Copyright />
      </Box>

    </Container>

  );

}


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  mainContainer: {
    marginTop: theme.spacing(20),
  },

  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
  },

}));


export default withRouter(Home);