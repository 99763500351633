
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { withRouter } from "react-router";
import { Typography, Container, Paper } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';


const ProjectSubmitSuccess = ({ history }) => {

    const classes = useStyles();

    return (

        <Container className={classes.mainContainer} maxWidth="md">
            <Paper className={classes.paper}>

                <Typography variant="h4">Project submitted successfully!</Typography>

                <Typography style={{marginTop: "20px"}} variant="body1" component="p">Once approved, the project will be displayed in our projects page.</Typography>

                <DoneIcon className={classes.tickIcon} />
            </Paper>

        </Container>
    );
}

export default withRouter(ProjectSubmitSuccess);

const useStyles = makeStyles((theme) => ({

    mainContainer: {
        marginTop: theme.spacing(20)
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "400px",
    },
    tickIcon: {
        marginTop: "40px",
        height: "80px",
        width: "80px",
    },

}));

