import React, { useContext, useEffect } from 'react';
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../Auth";
import * as FirebaseServices from "../../Services/firebase";
import ProjectForm from './ProjectForm';

const EditProject = ({ match }) => {
  const history = useHistory();
  const usersContext = useContext(AuthContext);
  const {currentUser } = usersContext;
  const [userDetails, setUserDetails] = React.useState(null);
  const [project, setProject] = React.useState(null);

  // Prefilling the user details form with first/last name and email.
  useEffect(() => {
    const fetchData = async () => {
      try {
        const projectDetails = await FirebaseServices.getFullProjectDetails(match.params.id);
        setProject(projectDetails);
    //    const user = await FirebaseServices.getUserDetails(currentUser.uid);
        setUserDetails({
          firstName: projectDetails.firstName,
          lastName: projectDetails.lastName,
          email: projectDetails.email,
        });
      } catch (error) {
        alert(error);
      }
    };

    fetchData();
  }, [currentUser, match.params.id]);


  const onSubmit = async (values, imageFilePath, setIsOpen, industryValue, stageValue, typeValue) => {
    
    console.log("Updating project");
    try {
      setIsOpen(true);
      console.log(values.industry);
      console.log(values.stage);
   ///   const originalimage = values.image[0];
   //   const imageAsFile = await Utils.getCompressedImage(originalimage);
    //  const imgDownloadURL = await FirebaseServices.uploadFile(currentUser.uid, imageAsFile);
      const imgDownloadURL = imageFilePath;
      await FirebaseServices.updateProjectWithId(match.params.id, currentUser.uid, values.title, values.description, values.lookingfor,
        values.externalLink, industryValue, stageValue, imgDownloadURL, values.city, values.country, values.firstName, values.lastName, values.email, values.linkedin, typeValue);
      setIsOpen(false);
      history.push("/project-submit-success");
    } catch (error) {
      setIsOpen(false);
      console.log(error);
    }
  }
  return (
    project && userDetails && (<ProjectForm isEditMode={true} userDetails={userDetails} projectDetails={project} submitForm={onSubmit}></ProjectForm>)
  );
}

export default withRouter(EditProject);