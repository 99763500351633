import { Box, FormControl, Paper } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { Fragment, useContext, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { withRouter } from "react-router";
import * as Yup from 'yup';
import { AuthContext } from "../../Auth";
import IndustryChip from "../../Components/IndustryChip";
import Copyright from '../../Copyright';
import ProfileInstructions from './ProfileInstructions';
import { FormLabel, Radio, RadioGroup } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ProgressDialog from '../../Components/ProgressDialog';
import AvatarPicker from '../../Components/AvatarPicker';

const EditProfile = (props) => {
    const classes = useStyles();

    const usersContext = useContext(AuthContext);
    const { currentUser } = usersContext;
    const [imageFilePath, setImageFilePath] = React.useState('');

    const [skills, setSkills] = React.useState([]);
    const [industries, setIndustries] = React.useState([]);
    const [typeRadio, setTypeRadio] = React.useState(null);

    const [isOpen, setIsOpen] = React.useState(false);

    const MenuProps = {
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left"
        },
        getContentAnchorEl: null
    }

    const industryList = ["ecommerce", "education", "energy", "entertainment", "fashion", "financial", "health", "manufacturing", "nutrition", "realestate", "saas", "social", "other"];

    const skillsList = [
        'Business Development',
        'Sales',
        'Marketing',
        'Finance',
        'Software Development',
        'Hardware Development',
        'Product Development',
        'Engineering',
        'R&D',
        'Design',
        'Communications',
        'Legal',
    ];


    const schema = Yup.object({
        firstName: Yup.string()
            .required('Required'),
        lastName: Yup.string()
            .required('Required'),
        email: Yup.string()
            .required('Required'),
        linkedin: Yup.string()
            .required('Required'),
        city: Yup.string()
            .max(15, 'Must be 15 characters or less')
            .required('Required'),
        country: Yup.string()
            .max(15, 'Must be 15 characters or less')
            .required('Required'),
        type: Yup.string()
            .required('Required'),
        //  skills: Yup.array()
        //   .required('Required'),
        // industries: Yup.array().default([]),
        lookingfor: Yup.string()
            .min(150, 'Must be 150 characters or more')
            .max(400, 'Must be 250 characters or less')
            .required('Required'),

        externalLink: Yup.string().default(''),
        publishReason: Yup.string()
            .max(400, 'Must be 250 characters or less')
            .required('Required'),
    });


    const { register, handleSubmit, errors, reset, unregister, setValue } = useForm({
        validationSchema: schema
    });


    // Prefilling the user details form with first/last name and email.
    useEffect(() => {
        const fetchData = async () => {
            //  setIsLoading(true);
            try {
                reset({
                    firstName: props.userDetails.firstName,
                    lastName: props.userDetails.lastName,
                    email: props.userDetails.email,
                })

                if (props.isEditMode) {
                    console.log("In edit mode...");
                    setIndustries(props.profileDetails.industries);
                    setSkills(props.profileDetails.skills);
                    setTypeRadio(props.profileDetails.type);
                    reset({
                        firstName: props.userDetails.firstName,
                        lastName: props.userDetails.lastName,
                        email: props.userDetails.email,
                        type: props.profileDetails.type,
                        linkedin: props.profileDetails.linkedin,
                        city: props.profileDetails.city,
                        country: props.profileDetails.country,
                        lookingfor: props.profileDetails.lookingFor,
                        externalLink: props.profileDetails.externalLink,
                        publishReason: props.profileDetails.publishReason,
                    })
                }
            } catch (error) {
                alert(error);
            }
            ///  setIsLoading(false);
        };

        fetchData();
    }, [currentUser, reset, props.profileDetails, props.userDetails, props.isEditMode]);

    useEffect(() => {

        register({ name: "type" });
        return () => unregister(["type"]);
    }, [register, unregister]);


    const handleChange = (event) => {
        const maxAllowed = 3

        if (skills.length >= maxAllowed && event.target.value.length > skills.length) {
        }
        else {
            setSkills(event.target.value);
        }

    };

    const handleType = e => {
        const { value } = e.target;
        //set value of Radio button
        setTypeRadio(value);
        //set value in hook-form
        setValue("type", value);
    };

    const handleChangeIndustry = (event) => {
        const maxAllowed = 3

        if (industries.length >= maxAllowed && event.target.value.length > industries.length) {
        }
        else {
            setIndustries(event.target.value);
        }

    };

    const onSubmit = async (values) => {

        // the last 3 parameters used for edit
        props.submitForm(values, imageFilePath, setIsOpen, skills, industries, typeRadio);

    }


    const shouldDisableCheckbox = value => {
        const maxAllowed = 3
        return skills.length >= maxAllowed && skills.indexOf(value) === -1
    }

    const shouldDisableIndustries = value => {
        const maxAllowed = 3
        return industries.length >= maxAllowed && industries.indexOf(value) === -1
    }

    return (

        <Fragment>
            <Container maxWidth="md" className={classes.mainContainer}>
                <ProfileInstructions />
            </Container>
            <Container maxWidth="sm">

                <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>

                    {/*///////////////////////// PAPER PART 1 //////////////////////////////*/}
                    <Paper elevation={3}>
                        <Container component="main" maxWidth="sm">
                            <CssBaseline />
                            <div className={classes.paper}>

                                <Avatar className={classes.avatar} color="primary">
                                    1/3
            </Avatar>
                                <Typography component="h1" variant="h5" className={classes.paperTitle}>
                                    Basic Information
            </Typography>

                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField

                                            autoComplete="fname"
                                            name="firstName"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="firstName"
                                            inputRef={register}
                                            error={errors.firstName ? true : false}
                                            helperText={errors.firstName ? (
                                                errors.firstName.message
                                            ) : null}

                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField

                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="lastName"
                                            name="lastName"
                                            autoComplete="lname"
                                            inputRef={register}
                                            error={errors.lastName ? true : false}
                                            helperText={errors.lastName ? (
                                                errors.lastName.message
                                            ) : null}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField

                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="email"
                                            name="email"
                                            autoComplete="email"

                                            inputRef={register}
                                            error={errors.email ? true : false}
                                            helperText={errors.email ? (
                                                errors.email.message
                                            ) : "Contact email address"}

                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            defaultValue={!props.isEditMode && ""}
                                            variant="outlined"
                                            fullWidth
                                            required
                                            name="linkedin"
                                            label="LinkedIn"
                                            type="linkedin"
                                            id="linkedin"
                                            inputRef={register}
                                            error={errors.linkedin ? true : false}
                                            helperText={errors.linkedin ? (
                                                errors.linkedin.message
                                            ) : "Linkedin profile link"}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            defaultValue={!props.isEditMode && ""}
                                            multiline
                                            variant="outlined"
                                            required
                                            fullWidth
                                            name="city"
                                            label="City"
                                            type="city"
                                            id="city"
                                            inputRef={register}
                                            error={errors.city ? true : false}
                                            helperText={errors.city ? (
                                                errors.city.message
                                            ) : "City you are based in"}

                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            defaultValue={!props.isEditMode && ""}
                                            multiline
                                            variant="outlined"
                                            required
                                            fullWidth
                                            name="country"
                                            label="Country"
                                            type="country"
                                            id="country"
                                            inputRef={register}
                                            error={errors.country ? true : false}
                                            helperText={errors.country ? (
                                                errors.country.message
                                            ) : "Country you are based in"}

                                        />
                                    </Grid>

                                </Grid>


                            </div>
                        </Container>
                    </Paper>

                    {/*///////////////////////// PAPER PART 2 //////////////////////////////*/}
                    <Paper elevation={3}>
                        <Container component="main" maxWidth="sm">
                            <CssBaseline />
                            <div className={classes.paper}>

                                <Avatar className={classes.avatar} color="primary">
                                    2/3
            </Avatar>
                                <Typography component="h1" variant="h5" className={classes.paperTitle}>
                                    Profile Information
            </Typography>

                                <Grid container spacing={4}>


                                    <Grid item xs={12}>
                                        <FormControl component="fieldset">
                                            <FormLabel required error={errors.type} component="legend">Looking to become:</FormLabel>
                                            <RadioGroup color="primary" aria-label="stage" name="stage" value={typeRadio} onChange={handleType}>
                                                <FormControlLabel value="cofounder" control={<Radio color="primary" name="type" inputRef={register} />} label="Co-Founder" />
                                                <FormControlLabel value="intern" control={<Radio color="primary" name="type" inputRef={register} />} label="Intern" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl} error={errors.skills}>
                                            <InputLabel id="demo-mutiple-checkbox-label">Skills</InputLabel>
                                            <Select
                                                labelId="demo-mutiple-checkbox-label"
                                                id="demo-mutiple-checkbox"
                                                multiple
                                                value={skills}
                                                inputRef={register}
                                                onChange={handleChange}
                                                input={<Input />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                            >
                                                {skillsList.map((skill) => (
                                                    <MenuItem key={skill} value={skill}>
                                                        <Checkbox disabled={shouldDisableCheckbox(skill)} checked={skills.indexOf(skill) > -1} />
                                                        <ListItemText primary={skill} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.skills && <FormHelperText>{errors.skills.message}</FormHelperText>}
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="demo-mutiple-checkbox-label">Fields of interest</InputLabel>
                                            <Select
                                                labelId="demo-mutiple-checkbox-label"
                                                id="demo-mutiple-checkbox"
                                                multiple
                                                value={industries}
                                                inputRef={register}
                                                onChange={handleChangeIndustry}
                                                input={<Input />}
                                                renderValue={(selected) => (

                                                    selected.map((value) => (
                                                        <IndustryChip key={value} industry={value} />
                                                    ))

                                                )}
                                                MenuProps={MenuProps}
                                            >
                                                {industryList.map((industry) => (
                                                    <MenuItem key={industry} value={industry}>
                                                        <Checkbox disabled={shouldDisableIndustries(industry)} checked={industries.indexOf(industry) > -1} />
                                                        <ListItemText primary={industry.charAt(0).toUpperCase() + industry.slice(1)} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>



                                    <Grid item xs={12}>
                                        <TextField
                                            defaultValue={!props.isEditMode && ""}
                                            multiline
                                            rows={5}
                                            rowsMax={Infinity}
                                            variant="outlined"
                                            required
                                            fullWidth
                                            placeholder="I am looking to join a project that..."
                                            name="lookingfor"
                                            label="Which kind of projects would you like to join? "
                                            type="lookingfor"
                                            id="lookingfor"
                                            inputRef={register}
                                            error={errors.lookingfor ? true : false}
                                            helperText={errors.lookingfor ? (
                                                errors.lookingfor.message
                                            ) : "Please elaborate on your interests (150-250 characters)"}

                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            defaultValue={!props.isEditMode && ""}
                                            variant="outlined"
                                            fullWidth
                                            placeholder="http://www"
                                            id="externalLink"
                                            label="External Link"
                                            name="externalLink"
                                            helperText="Link to your website, blog, one-pager, mock-ups or anything that others might find relevant."
                                            inputRef={register}

                                        />
                                    </Grid>

                                    <Grid item xs={12} align="center">
                                      <Typography variant="body1" component="h5">
                                           Upload an image, it can be a logo, concept, etc..
                                     </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <AvatarPicker imageFilePath={imageFilePath} setImageFilePath={setImageFilePath} ></AvatarPicker>
                                    </Grid>



                                </Grid>


                            </div>

                        </Container>
                    </Paper>

                    {/*///////////////////////// PAPER PART 2 //////////////////////////////*/}
                    <Paper elevation={3}>
                        <Container component="main" maxWidth="sm">
                            <CssBaseline />
                            <div className={classes.paper}>

                                <Avatar className={classes.avatar} color="primary">
                                    3/3
            </Avatar>
                                <Typography component="h1" variant="h5" className={classes.paperTitle}>
                                    Extras
            </Typography>

                                <Grid container spacing={4}>

                                    <Grid item xs={12}>
                                        <TextField
                                            defaultValue={!props.isEditMode && ""}
                                            multiline
                                            rows={5}
                                            rowsMax={Infinity}
                                            variant="outlined"
                                            required
                                            fullWidth
                                            name="publishReason"
                                            label="Why do you want to post your profile? "
                                            type="publishReason"
                                            id="publishReason"

                                            inputRef={register}
                                            error={errors.description ? true : false}
                                            helperText={errors.description ? (
                                                errors.description.message
                                            ) : "Not visible to others"}

                                        />
                                    </Grid>

                                </Grid>

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    Submit
               </Button>
                            </div>

                        </Container>
                    </Paper>


                </form>

              
                <ProgressDialog isOpen={isOpen}> </ProgressDialog>
            </Container>
            <Box mt={8}>
                    <Copyright />
                </Box>
        </Fragment>
    );

}


const useStyles = makeStyles((theme) => ({
    paper2: {

    },
    paper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    paperTitle: {
        marginBottom: theme.spacing(2),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        width: theme.spacing(6),
        height: theme.spacing(6),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },

    mainContainer: {
        marginTop: theme.spacing(15),
    },

    formControl: {
        margin: theme.spacing(0),
        minWidth: "100%",
        maxWidth: "100%",
    },

}));


export default withRouter(EditProfile);