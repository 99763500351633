
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { withRouter } from "react-router";

const Terms = () => {

    const classes = useStyles();

    return (
        <Container className={classes.mainContainer} maxWidth="lg">
            <Paper>
                <Box m={3} pt={3} py={3}>
                    <p>
                        Use of this site constitutes consent to the following:

                        The user hereby expressly agrees that the use of the site is at his sole risk and risk. The University allows the user of this site to generate connections and collaborations, but it does not guarantee results that can be achieved from using the site and does not guarantee the projects, qualities, information displayed on the site by any of the Founders and / or participants.
                        The projects on the site are presented under the responsibility of the Founders / Students, and there is no recommendation and / or expression of opinion from the university regarding them.
                        The University will not be directly and / or indirectly responsible for any case in which any offer on the site was not accepted (for any reason) and / or for any technical problem that prevents the use of the site.
                        The University will not be held responsible for any illegal activity by participants on the Site.
                        Without derogating from the above, the University will not be liable, for any damage, whether direct or indirect, which is caused due to the use of the site, and / or which was caused as a result of an engagement / cooperation, / agreement as a result of or in connection with the use of the site.
                        </p>
                </Box>
          
            </Paper>

        </Container>
    );
}

export default withRouter(Terms);

const useStyles = makeStyles((theme) => ({

    mainContainer: {
        marginTop: theme.spacing(20),
    },
    instructions: {
        width: '100%',
        maxWidth: 1060,
        backgroundColor: theme.palette.background.paper,
    },

    listItemText: {
        marginLeft: 40,

    }
}));

