import Chip from '@material-ui/core/Chip';
import EcoOutlinedIcon from '@material-ui/icons/EcoOutlined';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import React from 'react';

export default function StageChip({ stage }) {

        var customChip;
        switch (stage.toLowerCase()) {
            case 'idea':
                customChip =  <Chip size="small" color="primary" style={{ borderColor: '#a9d18e', color: '#a9d18e' }}  label="Idea" variant="outlined" icon={<EmojiObjectsOutlinedIcon />} />
                break;
            case 'prototype':
                customChip =  <Chip size="small" color="primary" style={{  borderColor: '#8497b0', color: '#8497b0' }} label="Prototype" variant="outlined" icon={<EcoOutlinedIcon />} />
                break;
            case 'users':
                customChip =  <Chip size="small" color="primary" style={{  borderColor: '#8faadc', color: '#8faadc' }} label="Users"variant="outlined" icon={<PeopleAltOutlinedIcon />} />
                break;
    
            default:
                customChip = <Chip variant="outlined" icon={<EmojiObjectsOutlinedIcon />} />
    
        }
          return customChip;

}